import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { getScannersSelector, getScannersCountSelector } from "../../selectors/scannerSelector"
import { getScanners, addNewScanner, deleteScanner } from "../../actions/scannersActions"
import Table from "../../components/Table"
import { getCompanySelector } from "../../selectors/companySelector"
import messages from "./languages"
import qs from "query-string"
import { getCompanies } from "../../actions/companyActions"
import { getUserData } from "../../selectors/userSelector"
import { FormattedMessage, injectIntl } from 'react-intl';
import commonmessages from "../commonLanguages"
import CommonModal from "../../components/CommonModal"

const ScannersPage = ({location, intl, history}) => {
    const dispatch = useDispatch();
    const [currentPage, setcurrentPage] = useState(1)
    const [openedNewModal, setopenedNewModal] = useState(false)
    const [limit, setlimit] = useState(10)

    const user = useSelector(getUserData)
    const companies = useSelector(getCompanySelector)
    const scanners = useSelector(getScannersSelector)
    const scannersCount = useSelector(getScannersCountSelector)
    
    const mountFunction = () => {
        window.scrollTo(0,0)
        let l = location;
        let search = qs.parse(l.search);

        if (search.offset && search.limit) {
            setlimit(search.limit)
            let currentPage = (search.offset / search.limit) === 0 ? 1 : (search.offset / search.limit)
            setcurrentPage(currentPage)
        } else {
            search.offset = 0;
            search.limit = 10;
        }

        search = qs.stringify(search);
        dispatch(push(`${location.pathname}?${search}`));
        dispatch(getScanners(search, intl))
        if (user.userGroupName && user.userGroupName === "Super Admin")
            dispatch(getCompanies("", intl))
    }

    const modalfields = [
        {name: "name", show: true, label: intl.formatMessage(messages.nameLabel), placeholder: intl.formatMessage(messages.namePlaceholder)},
        {name: "companyId", show: user.userGroupName === "Super Admin", label: intl.formatMessage(messages.companyLabel), placeholder: intl.formatMessage(messages.companyPlaceholder), tag: "select", data: companies.map(c => {
            return {label: c.name, value: c.id}
        }), multi: false}
    ]

    useEffect(mountFunction, [location.search])

    const fields = [
        //"id",
        "name",
        "createdAt"
    ]

    const textfields = [
        intl.formatMessage(commonmessages.nameString),
        intl.formatMessage(commonmessages.createdAtString),
    ]

    const deleteButtonAction = (id) => () => {
        if (window.confirm(intl.formatMessage(messages.deleteString))) {
            dispatch(deleteScanner(id, intl))
        }
    }

    const openNewModal = () => {
        setopenedNewModal(!openedNewModal)
    }


    const _addNew = (data) => {
        data.active = true;
        if (data.companyId) {
            data.companyId = data.companyId.value
        }
        dispatch(addNewScanner(data, intl));
        setopenedNewModal(false)
    }

    const pageChange = (page) => {
        let l = location
        let search = qs.parse(l.search)
        setcurrentPage(page);
        search.offset = (page * search.limit) - search.limit
        l.search = qs.stringify(search)
        dispatch(push(`${location.pathname}?${l.search}`))
        dispatch(getScanners(l.search, intl))
    }
    
    return (
        <div className="page-wrapper h-100">
            <div className="container-fluid">
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">
                            <FormattedMessage {...messages.title} />
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center text-right">
                        {user.userGroupName && (user.userGroupName === 'Super Admin' || user.userGroupName === 'Admin') && <button onClick={openNewModal} type="button" className="btn btn-info"><i className="fa fa-plus-circle"></i> <FormattedMessage {...messages.newProject} /></button>}
                    </div>
                    <div className="">
                    </div>
                </div>
                <Table 
                    fields={fields}
                    dataArr={scanners}
                    count={scannersCount}
                    tableHeading={intl.formatMessage(messages.projectsString)}
                    deleteAction={user.userGroupName && (user.userGroupName === 'Super Admin' || user.userGroupName === 'Admin') && deleteButtonAction}
                    pageChange={pageChange}
                    currentPage={currentPage}
                    limit={limit}
                    textfields={textfields}
                />
            </div>
            {openedNewModal && <CommonModal 
                messages={messages}
                form="NewScannerModal"
                fields={modalfields}
                closeModal={openNewModal} 
                openModal={openedNewModal}
                addNew={_addNew}
            />}
        </div>
    )
}

export default injectIntl(ScannersPage);