import { defineMessages } from 'react-intl';

export const scope = 'app.errors.actions';

export default defineMessages({
  loginError: {
    id: `${scope}.loginError`,
    defaultMessage: 'Napačno geslo ali uporabniško ime'
  },
  errorTitle: {
    id: `${scope}.errorTitle`,
    defaultMessage: 'Napaka'
  },
  commonError: {
    id: `${scope}.commonError`,
    defaultMessage: 'Prišlo je do napake, probajte spet kasneje.'
  },
  editBText: {
    id: `${scope}.editBText`,
    defaultMessage: 'Uredi'
  },
  deleteBText: {
    id: `${scope}.deleteBText`,
    defaultMessage: 'Zbriši'
  },
  successTitle: {
    id: `${scope}.successTitle`,
    defaultMessage: 'Uspešno'
  },
  commonSuccess: {
    id: `${scope}.commonSuccess`,
    defaultMessage: 'Uspešno ste dodali'
  },
  commonEditSuccess: {
    id: `${scope}.commonEditSuccess`,
    defaultMessage: 'Uspešno ste uredili'
  },
  commonDeleteSuccess: {
    id: `${scope}.commonDeleteSuccess`,
    defaultMessage: 'Uspešno ste zbrisali'
  },
  successSentMail: {
    id: `${scope}.successSentMail`,
    defaultMessage: `Emails successfuly sent`
  }
});