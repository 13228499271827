import { ROOT_URL, TOGGLE_SIDEBAR, GET_SEARCH, SET_IS_SEARCHING, notification, TOGGLE_SEARCH, LOADING } from "../constants"
import axios from "axios"
import { store } from 'react-notifications-component';
import messages from './messages'

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        store.addNotification({
            title: "Session expired",
            message: "Your session has expired, please login again",
            type: 'warning',
            ...notification
        })
        window.location = "/login"
    } else {
        return Promise.reject(error);
    }
});

export const toggleSidebar = () => {
    return dispatch => {
        dispatch({type: TOGGLE_SIDEBAR, payload: true})
    }
}

export const toggleSearch = () => {
    return (dispatch) => {
        dispatch({type: TOGGLE_SEARCH, payload: true})
    }
}

export const searchCharacters = (string, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.get(`${ROOT_URL}/search?keyword=${string}`)
		.then((response) => {
            if (response.status === 200) {
                dispatch({type: GET_SEARCH, payload: response.data})
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}

export const setIsSearching = (flag) => {
    return (dispatch) => {
        dispatch({type: SET_IS_SEARCHING, payload: flag})
    }
}

export const setSearchResult = () => {
    return (dispatch) => {
        dispatch({type: GET_SEARCH, payload: []})
    }
}