export const getSidebarToggle = store => {
    return store.getIn(["main", "toggleSidebar"])
}

export const selectHistory = store => {
    return store.getIn(["router", "location"]).toJS()
}

export const selectIsSearching = store => {
    return store.getIn(["main", "isSearching"])
}
export const selectSearchResults = store => {
    return store.getIn(["main", "searchResults"])
}
export const selectSearchToggle = store => {
    return store.getIn(["main", "openSearch"])
}

export const selectLoading = store => {
    return store.getIn(["main", "loading"])
}
export const selectILoading = store => {
    return store.getIn(["main", "i_loading"])
}