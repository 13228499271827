export const getProjectsSelector = store => {
    let projects = store.getIn(['projects', 'projects'])
    projects.forEach(p => {
        p.companyName = p.Company && p.Company.name
    })

    return projects
}

export const getProjectsCountSelector = store => {
    return store.getIn(['projects', 'projectsCount'])
}

export const getProjectSelector = store => {
    let project = store.getIn(['projects', 'project'])

    let obj = {
        name: project.name,
        city: project.city,
        country: {label: project.country, value: project.country},
        isEnabled: project.isEnabled,
        pdrMaxIntegration: project.pdrMaxIntegration,
        companyId: project.Company ? {label: project.Company.name, value: project.Company.id} : {},
        dentSizeMapperId: project.DentSizeMapper ? {label: project.DentSizeMapper.name, value: project.DentSizeMapper.id} : {},
        Scanners: project.projectScanners,
        Insurances: project.projectInsurances
    }
    
    return obj;
}