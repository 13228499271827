import React from 'react';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form/immutable'
import Select from "react-select"
import Switch from 'react-switch';

import { FormattedMessage, injectIntl } from 'react-intl';

import { otherModalStyles } from "../../constants"

const CommonModal = ({openModal, closeModal, handleSubmit, messages, intl, addNew, editData, initialValues, fields}) => {

    const submitF = (data) => {
        data = data.toJS();
        addNew(data)
    }

    return (
        <Modal
            isOpen={openModal}
            contentLabel="New scanner"
            onRequestClose={closeModal}
            ariaHideApp={false}
            style={otherModalStyles}>
            <div className="h-100">
                
                <div className="card-header bg-info">
                    <h4 className="m-b-0 text-white">
                        {initialValues ? 
                        <FormattedMessage {...messages.etitle} />
                        :
                        <FormattedMessage {...messages.mtitle} />}
                    </h4>
                </div>
                <div className="card-body h-100">
                    <form onSubmit={handleSubmit(submitF)}>
                        <div className="form-body">
                            <h3 className="card-title"><FormattedMessage {...messages.Subtitle} /></h3>
                            <hr></hr>
                            <div className="row p-t-20">
                                {fields.map((f, i) => {
                                  if (f.show === false) return null;
                                  return (
                                      <Field key={i} name={f.name} multi={f.multi} label={f.label} component={f.tag === "select" ? renderSelect : f.tag === "switch" ? renderSwitch : renderField} errorText={intl.formatMessage(messages.errorText)} placeholder={f.placeholder} options={f.data} type={f.type || "text"} />
                                  )
                                })}
                            </div>
                        </div>
                        <div className="text-right">
                            <button type="submit" className="btn btn-success"> 
                                <i className="fa fa-check"></i> Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    )
}

const renderField = ({input, label, placeholder, errorText, warningText, type, meta: { touched, error, warning, valid }}) => (
  <div className="col-md-6">
    <div className={`form-group ${touched && error && "has-danger"}`}>
      <label className="control-label">{label}</label>
      <input type={type} {...input} className="form-control" placeholder={placeholder} />
      {touched && error && <span className="form-control-feedback">{errorText}</span>}
    </div>
  </div>
)

const renderSelect = ({multi, input, placeholder, options, label, errorText, warningText, type, meta: { touched, error, warning, valid }}) => (
  <div className="col-md-6">
    <div className={`form-group ${touched && error && "has-danger"}`}>
      <label className="control-label">{label}</label>
      <Select
        placeholder={placeholder}
        value={input.value}
        {...input}
        onBlur={() => {input.onBlur(input.value)}}
        options={options}
        isMulti={multi}
      />
      {touched && error && <span className="form-control-feedback">{errorText}</span>}
    </div>
  </div>
)

const renderSwitch = ({input, label, placeholder, divClass, type, meta: {touched, warning, error}}) => {
  return (
    <div className="col-lg-3">
      <label className="control-label">{label}</label><br/>
      <Switch onChange={() => input.onBlur(!input.value)} {...input} checked={input.value === "" ? false : input.value} className="" />
    </div>
  );
};

const validate = (values) => {
    values = values.toJS();
    let errors = {};
    
    if (!values.name) {
        errors.name = true
    }
    
    return errors
}

export default reduxForm({
    validate
})(injectIntl(CommonModal));