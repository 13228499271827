import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  getProjectsSelector,
  getProjectsCountSelector,
  getProjectSelector,
} from '../../selectors/projectsSelector';
import { getCountriesSelector } from '../../selectors/countriesSelector';
import { getCompanySelector } from '../../selectors/companySelector';
import { getDentSelector } from '../../selectors/dentSelector';
import { getScannersSelector } from '../../selectors/scannerSelector';
import { getInsurancesSelector } from '../../selectors/insuranceSelector';
import {
  getProjects,
  addNewProject,
  editProject,
  deleteProject,
  getProject,
} from '../../actions/projectsActions';
import { getCountries } from '../../actions/countriesActions';
import { getCompanies } from '../../actions/companyActions';
import { getDents } from '../../actions/dentActions';
import { getUserData } from '../../selectors/userSelector';
import { getScanners } from '../../actions/scannersActions';
import { getInsurances } from '../../actions/insurancesActions';
import Table from '../../components/Table';
import messages from './languages';
import DisplayProjectModal from './components/displayProjectModal.js';
import qs from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import commonmessages from '../commonLanguages';
import CommonModal from '../../components/CommonModal';

const ProjectsPage = ({ location, intl, history }) => {
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [openedNewModal, setopenedNewModal] = useState(false);
  const [openedDisplayModal, setopenedDisplayModal] = useState(false);
  const [limit, setlimit] = useState(10);
  const projects = useSelector(getProjectsSelector);
  const project = useSelector(getProjectSelector);
  const scanners = useSelector(getScannersSelector);
  const user = useSelector(getUserData);
  const countries = useSelector(getCountriesSelector);
  const companies = useSelector(getCompanySelector);
  const insurances = useSelector(getInsurancesSelector);
  const dents = useSelector(getDentSelector);
  const projectsCount = useSelector(getProjectsCountSelector);

  const modalfields = [
    {
      name: 'name',
      show: true,
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder),
    },
    {
      name: 'city',
      show: true,
      label: intl.formatMessage(messages.cityLabel),
      placeholder: intl.formatMessage(messages.cityPlaceholder),
    },
    {
      name: 'country',
      show: true,
      label: intl.formatMessage(messages.countryLabel),
      placeholder: intl.formatMessage(messages.countryPlaceholder),
      tag: 'select',
      data: countries.map((c) => {
        return { label: c.name, value: c.name };
      }),
      multi: false,
    },
    {
      name: 'isEnabled',
      show: true,
      label: intl.formatMessage(messages.enabledLabel),
      tag: 'switch',
    },
    {
      name: 'pdrMaxIntegration',
      show: true,
      label: intl.formatMessage(messages.pdrLabel),
      tag: 'switch',
    },
    {
      name: 'companyId',
      show: user.userGroupName && user.userGroupName === 'Super Admin' && true,
      label: intl.formatMessage(messages.companyLabel),
      placeholder: intl.formatMessage(messages.companyPlaceholder),
      tag: 'select',
      data: companies.map((c) => {
        return { label: c.name, value: c.id };
      }),
      multi: false,
    },
    {
      name: 'dentSizeMapperId',
      show: true,
      label: intl.formatMessage(messages.dentLabel),
      placeholder: intl.formatMessage(messages.dentPlaceholder),
      tag: 'select',
      data: dents.map((c) => {
        return { label: c.name, value: c.id };
      }),
      multi: false,
    },
    {
      name: 'scanners',
      show: true,
      label: intl.formatMessage(messages.scannerLabel),
      placeholder: intl.formatMessage(messages.scannerPlaceholder),
      tag: 'select',
      data: scanners.map((c) => {
        return { label: c.name, value: c.id };
      }),
      multi: true,
    },
    {
      name: 'insurances',
      show: true,
      label: intl.formatMessage(messages.insuranceLabel),
      placeholder: intl.formatMessage(messages.insurancesPlaceholder),
      tag: 'select',
      data: insurances.map((c) => {
        return { label: c.name, value: c.id };
      }),
      multi: true,
    },
  ];

  const mountFunction = () => {
    window.scrollTo(0, 0);
    let l = location;
    let search = qs.parse(l.search);

    if (search.offset && search.limit) {
      setlimit(search.limit);
      let currentPage =
        search.offset / search.limit === 0 ? 1 : search.offset / search.limit;
      setcurrentPage(currentPage);
    } else {
      search.offset = 0;
      search.limit = 10;
    }

    search = qs.stringify(search);
    dispatch(push(`${location.pathname}?${search}`));
    dispatch(getProjects(search, intl));
    dispatch(getCountries('', intl));

    if (user.userGroupName && user.userGroupName === 'Super Admin')
      dispatch(getCompanies('', intl));
    dispatch(getDents('', intl));
    dispatch(getScanners('', intl));
    dispatch(getInsurances('', intl));
  };

  useEffect(mountFunction, [location.search]);

  const locationChangeListener = () => {
    let search = qs.parse(location.search);
    let displayId = search.displayId;

    if (displayId) {
      setopenedDisplayModal(true);
      setSelectedId(displayId);
      dispatch(getProject(displayId, intl));
    }
  };

  useEffect(locationChangeListener, [location]);

  const fields = ['name', 'companyId', 'city', 'country', 'createdAt'];

  const textfields = [
    intl.formatMessage(commonmessages.nameString),
    intl.formatMessage(commonmessages.companyLabel),
    intl.formatMessage(commonmessages.cityString),
    intl.formatMessage(commonmessages.countryString),
    intl.formatMessage(commonmessages.createdAtString),
  ];

  const editButtonAction = (row) => () => {
    setopenedDisplayModal(true);
    setSelectedId(row.id);
    dispatch(getProject(row.id, intl));
  };

  const deleteButtonAction = (id) => () => {
    if (window.confirm(intl.formatMessage(messages.deleteString))) {
      dispatch(deleteProject(id, intl));
    }
  };

  const openNewProjectModal = () => {
    setopenedNewModal(!openedNewModal);
  };

  const openDisplayProjectModal = () => {
    setopenedDisplayModal(!openedDisplayModal);
  };

  const _addNewProject = (data) => {
    let obj = {
      city: data.city,
      country: data.country.value,
      dentSizeMapperId: data.dentSizeMapperId.value,
      isEnabled: data.isEnabled || false,
      name: data.name,
      pdrMaxIntegration: data.pdrMaxIntegration || false,
      syncTarget: 2, //temporary fix
      scanners: data.scanners.map((s) => {
        return s.value;
      }),
      insurances: data.insurances.map((s) => {
        return s.value;
      }),
    };
    if (user.userGroupName && user.userGroupName === 'Super Admin') {
      obj.companyId = data.companyId.value;
    }
    if (user.userGroupName && user.userGroupName === 'Admin') {
      obj.companyId = user.companyId;
    }
    dispatch(addNewProject(obj, intl));
    setopenedNewModal(false);
  };

  const _editProject = (data) => {
    dispatch(editProject(data, selectedId, intl));
    setopenedDisplayModal(false);
  };

  const pageChange = (page) => {
    let l = location;
    let search = qs.parse(l.search);
    setcurrentPage(page);
    search.offset = page * search.limit - search.limit;
    l.search = qs.stringify(search);
    dispatch(push(`${location.pathname}?${l.search}`));
    dispatch(getProjects(l.search, intl));
  };

  return (
    <div className="page-wrapper h-100">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h3 className="text-themecolor">
              <FormattedMessage {...messages.title} />
            </h3>
          </div>
          <div className="col-md-7 align-self-center text-right">
            {user.userGroupName &&
              (user.userGroupName === 'Super Admin' ||
                user.userGroupName === 'Admin') && (
                <button
                  onClick={openNewProjectModal}
                  type="button"
                  className="btn btn-info"
                >
                  <i className="fa fa-plus-circle"></i>{' '}
                  <FormattedMessage {...messages.newProject} />
                </button>
              )}
          </div>
          <div className=""></div>
        </div>
        <Table
          fields={fields}
          dataArr={projects}
          count={projectsCount}
          tableHeading={intl.formatMessage(messages.projectsString)}
          displayAction={
            user.userGroupName &&
            (user.userGroupName === 'Super Admin' ||
              user.userGroupName === 'Admin') &&
            editButtonAction
          }
          deleteAction={
            user.userGroupName &&
            (user.userGroupName === 'Super Admin' ||
              user.userGroupName === 'Admin') &&
            deleteButtonAction
          }
          pageChange={pageChange}
          currentPage={currentPage}
          limit={limit}
          textfields={textfields}
        />
      </div>
      {openedNewModal && (
        <CommonModal
          messages={messages}
          closeModal={openNewProjectModal}
          openModal={openedNewModal}
          fields={modalfields}
          form="NewProjectForm"
          addNew={_addNewProject}
        />
      )}
      {openedDisplayModal && (
        <DisplayProjectModal
          initialValues={project}
          messages={messages}
          user={user}
          closeModal={openDisplayProjectModal}
          openModal={openedDisplayModal}
          countries={countries}
          scanners={scanners}
          insurances={insurances}
          companies={companies}
          dents={dents}
          editProject={_editProject}
        />
      )}
    </div>
  );
};

export default injectIntl(ProjectsPage);
