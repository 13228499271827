import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getScanSelector } from '../../../selectors/scansSelector'
import { selectILoading } from '../../../selectors/mainSelector'
import { getScanExt } from "../../../actions/scansActions";
// import uuid from "uuid";
import messages from "../languages"
// import Video from "../../../components/VideoPlayer"
// import Snapshot from "./snapshots"
import {Img} from 'react-image'
// import Thumbnail from "../../../components/Thumbnail"
import { FormattedMessage, injectIntl } from 'react-intl';
import FollowersModal from "../../../components/FollowersModal"
import ModalVideo from "../../../components/PlayerModal"
import MimeTypes from "mime-types"
// import AwsGetImageService from "../../../utils/awsImagesService"
import { INLINE_LOADING } from "../../../constants"



import Carousel, { Modal, ModalGateway } from "react-images";

const RoScanDetailsPage = ({location, intl, match}) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [isFollowersModalOpen, setisFollowersModalOpen] = useState(false)
    const [isPlayerModalOpen, setisPlayerModalOpen] = useState(false)
    const [videoURL, setvideoURL] = useState(null)
    const thumbnails = ["Top","Left","Right","Left Rail","Right Rail"]

    const dispatch = useDispatch();

    const scan = useSelector(getScanSelector);
    const i_loading = useSelector(selectILoading)

    const reportFiles = scan.Files.filter(f => {
        return f.type.toLowerCase() === "report"
    })

    const videoFiles = scan.Files.filter(f => {
        return f.type.toLowerCase() === "video"
    })

    const openLightbox = (index) => () => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const mountFunction = () => {
        window.scrollTo(0,0)

        dispatch(getScanExt(match.params.id, intl))
    }

    useEffect(mountFunction, [])

    const renderSnapshots = (snap, index) => {
        // toImageUrl(snap)
        var fileURL = getFileUrl(snap)
        return (
            <div key={index} className="col-md-4 cursor-pointer mb-4">
                <img onClick={openLightbox(index)} alt={`snapshot-${index+1}`} className="w-100" src={fileURL} />
            </div>
        )
    }

    const getFileUrl = (file) => {
        if (file.awsObject) {
            var buffer = file.awsObject.Body

            var arrayBuffer = new ArrayBuffer(buffer.length);
            var view = new Uint8Array(arrayBuffer);
            for ( var i = 0; i < buffer.length; i++) {
                view[i] = buffer[i];
            }

            var file1 = new Blob([arrayBuffer], {type: file.awsObject.ContentType});

            return URL.createObjectURL(file1);
        }
    }

    const getThumbnailUrl = (videoFiles, title) => {
        var url = videoFiles.filter(element => {
            var splited = element.location.split('/')
            var file = splited[splited.length-1];
            file = file.split('.');
            var fileName = file[0];

            return fileName === title
        });

        if(url[0]) {

            var splitedUrl = url[0].location.split('/');
            splitedUrl[splitedUrl.length-1] = 'thumb_'+title+'.png';

            var thumbnailUrl = splitedUrl.join('/')

            return thumbnailUrl;
        }

    }

    const getVideoStreamUrl = (videoFiles, title) => {
        var url = videoFiles.filter(element => {
            var splited = element.location.split('/')
            var file = splited[splited.length-1];
            file = file.split('.');
            var fileName = file[0];

            return fileName === title
        });

        if(url[0]) {
            return url[0].location
        }

    }

    const renderCarouselImages = (file, index) => {
        var fileURL = getFileUrl(file)

        return {
            source: fileURL,
            caption: file.name
        }
    }

    const playVideo = (videoURL) => {
        setvideoURL(videoURL)
        setisPlayerModalOpen(true)
    }

    const closeVideoModal = () => {
        setisPlayerModalOpen(false)
        setvideoURL(null)
    }

    // const closeFModal = () => {
    //     setisFollowersModalOpen(false)
    // }

    // const openFModal = () => {
    //     setisFollowersModalOpen(true)
    // }

    const openReportFile = (file, j) => () => {
        var buffer = file.Body

        var a = document.createElement("a");
        document.body.appendChild(a);

        var arrayBuffer = new ArrayBuffer(buffer.length);
        var view = new Uint8Array(arrayBuffer);
        for ( var i = 0; i < buffer.length; i++) {
            view[i] = buffer[i];
        }

        var file1 = new Blob([arrayBuffer], {type: file.ContentType});
        var mimeType = MimeTypes.extension(file.ContentType)
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file1, `Report${j+1}.${mimeType}`);
        }
        else {
            var fileURL = URL.createObjectURL(file1);
            a.href = fileURL;
            a.target = '_blank';
            a.download = `Report${j+1}.${mimeType}`;
            a.click();
        }
    }

    const renderSingleReportFile = (file, i) => {
        return (
            <div key={i} className="col-md-2 text-center cursor-pointer">
                <span onClick={openReportFile(file.awsObject, i)}>
                    <img src="../assets/images/word-icon2.png" width="100" alt={`report-${i+1}`} />
                    <p className="text-center">Report {i+1}</p>
                </span>
            </div>
        )
    }


    return (
        <div className="page-wrapper h-100 ml-0">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header bg-info">
                                <h4 className="m-b-0 text-white">
                                    <FormattedMessage {...messages.stitle} />
                                    &nbsp;
                                    {scan.Car && scan.Car.licensePlate}
                                </h4>
                            </div>
                            <div className="card-body">
                                <div className="form-horizontal" role="form">
                                    <div className="form-body">
                                        {/* Scan */}
                                        <h3 className="box-title">
                                            <FormattedMessage {...messages.scanString} />
                                        </h3>
                                        <hr className="m-t-0 m-b-40"></hr>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3">
                                                        <FormattedMessage {...messages.createdAtString} />:
                                                    </label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {moment(scan.createdAt).format("DD-MM-YYYY HH:MM")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.finishedDateString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {moment(scan.finishedDate).format("DD-MM-YYYY HH:MM")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        {/* Car */}
                                        <h3 className="box-title m-t-20">
                                            <FormattedMessage {...messages.carString} />
                                        </h3>
                                        <hr className="m-t-0 m-b-40"></hr>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3">
                                                        <FormattedMessage {...messages.carBrandString} />:
                                                    </label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Car && scan.Car.brand} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.carModelString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Car && scan.Car.model} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.licensePlateString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Car && scan.Car.licensePlate} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.doorCountString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Car && scan.Car.doorCount} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Customer */}
                                        <h3 className="box-title m-t-20"><FormattedMessage {...messages.customerString} /></h3>
                                        <hr className="m-t-0 m-b-40"></hr>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.countryString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Customer && scan.Customer.country} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.nameString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Customer && scan.Customer.firstName} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.lastnameString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Customer && scan.Customer.lastName} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.emailString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Customer && scan.Customer.email} </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group row">
                                                    <label className="control-label text-left text-md-left col-md-3"><FormattedMessage {...messages.telephoneString} />:</label>
                                                    <div className="col-md-9">
                                                        <p className="form-control-static"> {scan.Customer && scan.Customer.phoneNumber} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Reports */}
                                        <h3 className="box-title m-t-20"><FormattedMessage {...messages.reportString} />
                                        </h3>
                                        <hr className="m-t-0 m-b-40"></hr>
                                        <div className="row">
                                            {reportFiles.length > 0 ?
                                                reportFiles.map(renderSingleReportFile)
                                            :
                                                <div className="col-md-6">
                                                    <div className="form-group "><label className="form-control-static"> <FormattedMessage {...messages.noreportString} /></label></div>
                                                </div>
                                            }
                                        </div>
                                        

                                        {/* Followers */}
                                        {/* <h3 className="box-title"><FormattedMessage {...messages.followersString} />
                                            <button onClick={openFModal} className="ml-5 btn btn-rounded btn-success"><FormattedMessage {...messages.addFString} /></button>
                                            <button onClick={_sendEmail} className="ml-5 btn btn-rounded btn-success"><FormattedMessage {...messages.sendMailString} /></button>
                                        </h3> */}
                                        {/* <hr className="m-t-0 m-b-40"></hr>
                                        <div className="row">
                                            {scan.Followers.length > 0 ? scan.Followers.map((f, i) => {
                                                return (
                                                    <div key={i} className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="control-label text-right">{f.email}</label>
                                                        </div>
                                                    </div>
                                                )
                                            }) :
                                            <div className="col-md-6">
                                                <div className="form-group "><label className="form-control-static"> <FormattedMessage {...messages.nofollowersString} /></label></div>
                                            </div>}
                                        </div> */}

                                        {/* Video */}
                                        <h3 className="box-title m-t-20"><FormattedMessage {...messages.videoString} /></h3>
                                        <hr className="m-t-0 m-b-40"></hr>
                                        <div className="row">
                                                {/* {thumbnails.map((thumbnail, index) => {return <Thumbnail thumbnailFile={thumbnail} />})} */}
                                                {/* <div className="col-md-6 mt-4">
                                                    Video title
                                                    <Img
                                                        src={'https://dcchailpublic.s3.us-east-2.amazonaws.com/test-000000000000/Videos/thumb_LeftR-00001.png'}
                                                        loader={<img src="../assets/images/background/black-background.jpg" alt="loading" />}
                                                        // src={thumbnailURL}
                                                        unloader={"sdvsdvdc"}
                                                        width="100%"
                                                        height="auto"
                                                        container={(children) => {
                                                            return <div className="video-thumbnail">
                                                                {children}
                                                                <div className="overlay">
                                                                    <i className="far fa-play-circle overlay icon"></i>
                                                                </div>
                                                            </div>
                                                        }}
                                                    />
                                                </div> */}
                                                {thumbnails.map((title, index) => {

                                                    var thumbnail_title = title.replace(/ /g,"")

                                                    //Real link
                                                    // var thumbnailUrl = getThumbnailUrl(videoFiles, thumbnail_title)
                                                    var videoStreamUrl = getVideoStreamUrl(videoFiles, thumbnail_title)


                                                    //Test link
                                                    var thumbnailUrl = '../assets/images/avtocar.jpg';
                                                    // var videoStreamUrl = 'https://dcchailpublic.s3.us-east-2.amazonaws.com/test-000000000000/Videos/trans_LeftR.mp4.m3u8'

                                                    return (
                                                        <div className="col-md-6 col-lg-4 mt-4" key={index}>
                                                            <h3 className="box-title">{title} </h3>
                                                            <Img
                                                                src={thumbnailUrl}
                                                                loader={<img src="../assets/images/background/black-background.jpg" alt="loading" />}
                                                                unloader={"sdvsdvdc"}
                                                                width="100%"
                                                                height="auto"
                                                                container={(children) => {
                                                                    return <div className="video-thumbnail mt-2">
                                                                        {children}
                                                                        <div className="overlay" onClick={() => playVideo(videoStreamUrl)} vdeo-url={videoStreamUrl}>
                                                                            <i className="far fa-play-circle overlay icon"></i>
                                                                        </div>
                                                                    </div>
                                                                }}
                                                            />
                                                        </div>
                                                    )
                                                })}


                                            <div className="col-md-12">
                                                {/* <Video videoFiles={videoFiles} /> */}
                                                {/* <Thumbnail thumbnailFiles={thumbnailLinks}/> */}
                                                {/* {videoFiles.map((videoFile, index) => {return <Video videoFiles={videoFile} />})} */}
                                            </div>
                                            {/* <div className="col-md-12 text-right mt-2">
                                                {!i_loading ? <button disabled={i_loading} onClick={makeSnapShot} className="btn btn-success">
                                                    <i className="fa fa-check"></i> <FormattedMessage {...messages.snapshotString} />
                                                </button>
                                                : <img width="50" src="../assets/images/loading-gif.gif" alt="loading" />
                                                }
                                            </div> */}
                                        </div>
                                        {/* Snapshots */}
                                        <h3 className="box-title"><FormattedMessage {...messages.ssnapshotString} /></h3>
                                        <hr className="m-t-0 m-b-40"></hr>
                                        {scan.Snapshots.length > 0 && <div className="row">

                                            {/* <Snapshot /> */}
                                            {scan.Snapshots && scan.Snapshots.map(renderSnapshots)}
                                            {viewerIsOpen && <ModalGateway>
                                                <Modal onClose={closeLightbox}>
                                                    <Carousel
                                                    currentIndex={currentImage}
                                                    views={scan.Snapshots.map(renderCarouselImages)}
                                                    />
                                                </Modal>
                                            </ModalGateway>}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {isFollowersModalOpen && <FollowersModal initialValues={{followers: scan.Followers}} addNewFollowers={addNewFollowers} openModal={isFollowersModalOpen} closeModal={closeFModal} messages={messages} />} */}
            {isPlayerModalOpen && <ModalVideo openModal={isPlayerModalOpen} closeModal={closeVideoModal} scan={scan} match_id={match.params.id} messages={messages} video_link={videoURL}/>}
        </div>
      
    )
}

export default injectIntl(RoScanDetailsPage);