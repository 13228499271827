import React, { useState, useEffect } from "react";
import messages from './languages'
import LoginForm from '../../components/LoginForm'
import { useDispatch } from 'react-redux';
import { login, forgotPassword, resetPassword } from "../../actions/authActions"
import { injectIntl } from 'react-intl';
import ForgotPasswordForm from "./components/forgotPasswordForm";
import ResetPasswordForm from "./components/resetPasswordForm";
import qs from "query-string"
import { push } from 'connected-react-router'


const LoginPage = ({intl, location}) => {
    const dispatch = useDispatch();

    const [showForgotPass, setshowForgotPass] = useState(false);
    const [showResetPass, setshowResetPass] = useState(false);

    const mountFunction = () => {
        let l = location;
        let search = qs.parse(l.search);

        if (search.token) {
            setshowResetPass(true)
        }
    }

    useEffect(mountFunction, [])

    const submitLogin = (data) => {
        dispatch(login(data, intl))
    }

    const _handleResetPassword = () => {
        setshowForgotPass(!showForgotPass)
    }

    const _backToLogin = () => {
        setshowForgotPass(false)
    }

    const submitFP = (data) => {
        dispatch(forgotPassword(data.username, intl))
        setshowForgotPass(false)
    }

    const _submitRP = (data) => {
        let l = location
        let search = qs.parse(l.search)
        
        dispatch(resetPassword(data, search.token, intl))
        dispatch(push(`${location.pathname}`))
        setshowResetPass(false);
    }

    return (
        <section id="wrapper" className="login-register login-sidebar" style={{backgroundImage: `url(../assets/images/avtocar.jpg)`}}>
            <div className="login-box card">
                <div className="card-body">
                    {showResetPass ?
                        <ResetPasswordForm submitRP={_submitRP}  messages={messages} />
                    :
                    !showForgotPass ?
                        <LoginForm handleResetPassword={_handleResetPassword} submitLogin={submitLogin} messages={messages} />
                    :
                        <ForgotPasswordForm backToLogin={_backToLogin} submitFP={submitFP} messages={messages} />
                    }
                </div>
            </div>
        </section>
    )
}

export default injectIntl(LoginPage);