import Immutable from 'immutable';
import { GET_PROJECTS, ADD_PROJECT, GET_PROJECT } from '../constants';

const INITIAL_STATE = Immutable.fromJS({project: { Company: {}, DentSizeMapper: {}, Insurances: [], isEnabled: false, pdrMaxIntegration: false }, projects: [], projectsCount: 0});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_PROJECTS:
            nextState.projects = action.payload.rows
            nextState.projectsCount = action.payload.count
        break;
        case ADD_PROJECT:
            nextState.projects.push(action.payload)
            nextState.projectsCount++;
        break;
        case GET_PROJECT:
            nextState.project = {...nextState.project, ...action.payload}
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
