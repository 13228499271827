import React from "react";
import {
  Switch,
  Route
} from "react-router";
import { ConnectedRouter } from 'connected-react-router/immutable';
import { history } from './utils/history'
import { useSelector } from "react-redux"
import { selectHistory } from "./selectors/mainSelector"
import Sidebar from "./components/Sidebar"

import { PrivateRoute } from "./utils/Auth"

import Login from './containers/Login'
import Users from './containers/Users'
import Superadmins from "./containers/Users/components/superadmins"
import Admins from "./containers/Users/components/admins"
import Insuranceusers from "./containers/Users/components/insuranceUsers"
import Scanners from "./containers/Scanners"
import Projects from './containers/Projects'
import Insurances from './containers/Insurances'
import Cars from "./containers/Car"
// import Colors from "./containers/Colors"
import Dashboard from "./containers/Dashboard"
import Scans from "./containers/Scans"
import roScan from "./containers/Scans/components/roScan"
import ScanDetails from "./containers/Scans/components/scanDetails"

const Routes = () => {
  const location = useSelector(selectHistory);
  const uuidRegex = new RegExp(/scan\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'i')

  return (
    <ConnectedRouter history={history}>
      {(location.pathname !== "/login" && !uuidRegex.test(location.pathname)) && <Sidebar />}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/scan/:id" component={roScan} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute path="/projects" component={Projects} />
        <PrivateRoute path="/scanners" component={Scanners} />
        <PrivateRoute path="/insurances" component={Insurances} />
        <PrivateRoute path="/cars" component={Cars} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/superadmins" component={Superadmins} />
        <PrivateRoute path="/admins" component={Admins} />
        <PrivateRoute path="/insuranceusers" component={Insuranceusers} />
        {/* <PrivateRoute path="/colors" component={Colors} /> */}
        <PrivateRoute exact path="/scans" component={Scans} />
        <PrivateRoute path="/scans/:id" component={ScanDetails} />
      </Switch>
    </ConnectedRouter>
  );
}

export default Routes;