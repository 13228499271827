import React, { useEffect, useState, useRef } from "react";
// import Select from "react-select"
import ReactPlayer from 'react-player'
// import ReactHLS from 'react-hls';
// import {Img} from 'react-image'
// import { ROOT_URL } from "../../constants"
import AwsGetImageService from "../../utils/awsImagesService"


const VideoPlayer = ({videoLink, videoState}) => {

    const videoWrap = useRef(null);

    const [videoValue, setVideoValue] = useState(null)
    const [videoPlaying, setvideoPlaying] = useState(true)
    const [videoPlaybackRate, setvideoPlaybackRate] = useState(0.5)
    const [videoLinkState, setVideoLinkState] = useState(videoLink)
    const [videoLoaded, setVideoLoaded] = useState(false)

    const returnVideoState = (state) => {
        videoState(state)
    }

    const handleScrollSeek = async (e) => {
        if (e.deltaY > 0) { //scrolling down
            await videoWrap.current.seekTo(videoWrap.current.getCurrentTime() - 0.1, 'seconds')
        } else if (e.deltaY < 0) { //scrolling up
            await videoWrap.current.seekTo(videoWrap.current.getCurrentTime() + 0.1, 'seconds')
        }
        setvideoPlaying(false);
    }
    
    
    const mountFunction = () => {

        async function prepareVideo(link) {
            try{

                let splitedURL = link.split('/')
                splitedURL[2] = 'dcchailpublic.s3.us-east-2.amazonaws.com'
                splitedURL[5] = 'trans_'+splitedURL[5]+'.m3u8'


                var fileURL = splitedURL.join('/')

                const exists = await AwsGetImageService.checkS3File(fileURL)
                if(!exists) {
                    console.log('Requesting another source..')
                    const obj = await AwsGetImageService.getS3File(link, (f) => {
                        // return f;
                        const file = f;
                        // setVideoValue(file)
                    })

                    var buffer = obj.Body

                    var arrayBuffer = new ArrayBuffer(buffer.length);
                    var view = new Uint8Array(arrayBuffer);
                    for ( var i = 0; i < buffer.length; i++) {
                        view[i] = buffer[i];
                    }

                    var file1 = new Blob([arrayBuffer], {type: obj.ContentType});

                    var fileURL = URL.createObjectURL(file1);
                }

                
                // var fileURL = "https://dcchailpublic.s3.us-east-2.amazonaws.com/test-0000000000004/Videos/trans_LeftR.mp4.m3u8"

                setVideoValue(fileURL)
                returnVideoState(true)

            } catch(err) {
                console.log(err)
            }
        }
        
        const videoObj = prepareVideo(videoLinkState)

    }

    useEffect(mountFunction, [])

    return (
        <React.Fragment>



            <div className="row mr-0 ml-0 player-main-wrap" onWheel = {(e) => handleScrollSeek(e)}>
                <div className="col-md-12 pr-0 pl-0 ">
                    {!videoValue && <div className="video-player-wrapper">
                        <img width="50" src="../assets/images/loading-gif.gif" alt="loading" />
                    </div>}
                    {videoValue && <ReactPlayer 
                        ref={videoWrap}
                        url={videoValue}
                        controls={true}
                        height='100%'
                        playbackRate={videoPlaybackRate}
                        progressInterval={0.00001}
                        width='100%'
                     
                        playing={videoPlaying}
                        onSeek={function name(e) {
                            setvideoPlaybackRate(0.1);
                            setvideoPlaying(false);
                        }}
                        onPlay={function name(e) {
                            setvideoPlaying(true);
                            setvideoPlaybackRate(0.5);
                        }}
                        config={{ file: {
                                attributes: {
                                    crossOrigin: 'false'
                                },
                            }
                        }}
                    />}
                </div>
               
            </div>
           
        </React.Fragment>
    )
}

export default VideoPlayer;