export const getScansSelector = (store) => {
  let scans = store.getIn(['scans', 'scans']);
  scans.forEach((s) => {
    s.licensePlate = s.Car && s.Car.licensePlate;
    s.projectName = s.Project && s.Project.name;
    s.companyName = s.Company && s.Company.name;
  });

  return scans;
};

export const getScansCountSelector = (store) => {
  return store.getIn(['scans', 'scansCount']);
};

export const getScanSelector = (store) => {
  return store.getIn(['scans', 'scan']);
};
