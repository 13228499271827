import { defineMessages } from 'react-intl';

export const scope = 'app.components.Common';

export default defineMessages({
  licensePlateString: {
    id: `${scope}.licensePlateString`,
    defaultMessage: 'Registerska oznaka',
  },
  createdAtString: {
    id: `${scope}.createdAtString`,
    defaultMessage: 'Ustvarjeno',
  },
  finishedDateString: {
    id: `${scope}.finishedDateString`,
    defaultMessage: 'Končano',
  },
  synchronizedAtString: {
    id: `${scope}.synchronizedAtString`,
    defaultMessage: 'Sinhronizirano',
  },
  insuranceString: {
    id: `${scope}.insuranceString`,
    defaultMessage: 'Zavarovalnica',
  },
  nameString: {
    id: `${scope}.nameString`,
    defaultMessage: 'Ime',
  },
  scannersString: {
    id: `${scope}.scannersString`,
    defaultMessage: 'Scanners',
  },
  cityString: {
    id: `${scope}.cityString`,
    defaultMessage: 'Mesto',
  },
  countryString: {
    id: `${scope}.countryString`,
    defaultMessage: 'Država',
  },
  usernameString: {
    id: `${scope}.usernameString`,
    defaultMessage: 'Uporabniško ime',
  },
  idString: {
    id: `${scope}.idString`,
    defaultMessage: 'ID',
  },
  brandString: {
    id: `${scope}.brandString`,
    defaultMessage: 'Znamka',
  },
  modelString: {
    id: `${scope}.modelString`,
    defaultMessage: 'Model',
  },
  exposureTimeString: {
    id: `${scope}.exposureTimeString`,
    defaultMessage: 'Exposure time',
  },
  projectNameString: {
    id: `${scope}.projectNameString`,
    defaultMessage: `Ime projekta`,
  },
  superadminsUsergroupString: {
    id: `${scope}.superadminsUsergroupString`,
    defaultMessage: 'Super admins',
  },
  adminsUsergroupString: {
    id: `${scope}.adminsUsergroupString`,
    defaultMessage: 'Admins',
  },
  usersUsergroupString: {
    id: `${scope}.usersUsergroupString`,
    defaultMessage: 'Users',
  },
  insurancesUsergroupString: {
    id: `${scope}.insurancesUsergroupString`,
    defaultMessage: 'Insurance users',
  },
  companyLabel: {
    id: `${scope}.companyLabel`,
    defaultMessage: `Company`,
  },
});
