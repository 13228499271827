import axios from 'axios';
import { store } from 'react-notifications-component';
import messages from './messages';
import {
  ROOT_URL,
  notification,
  GET_SCANS,
  GET_SCAN,
  LOADING,
  INLINE_LOADING,
  GET_SNAPSHOT,
  UPDATE_SCAN_FILE,
  UPDATE_SNAPSHOT_FILE,
} from '../constants';
import AwsGetImageService from '../utils/awsImagesService';
import { getCompanies } from './companyActions';
// import AwsCloudfrontService from "../utils/cloudfrontService"

export const getScans = (data = 'limit=10&offset=0', intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scans?${data}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_SCANS, payload: response.data });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getScan = (id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scans/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_SCAN, payload: response.data });

          response.data.Files.forEach((file) => {
            if (file.type !== 'video') {
              AwsGetImageService.getObjectURL(file.location, (f) => {
                file.awsObject = f;
                // console.log(file)
                dispatch({ type: UPDATE_SCAN_FILE, payload: { file } });
              });
            } else {
              dispatch({ type: UPDATE_SCAN_FILE, payload: { file } });
            }
          });

          response.data.Snapshots.forEach((file) => {
            AwsGetImageService.getObjectURL(file.location, (f) => {
              file.awsObject = f;
              dispatch({ type: UPDATE_SNAPSHOT_FILE, payload: { file } });
              // dispatch({type: SNAPSHOT_FILES, payload: {file}})
            });
          });

          dispatch({ type: LOADING, payload: false });

          // TEST
          // response.data.Files.forEach(file => {
          //     AwsGetImageService.getObjectURL_test(file.location, (f) => {
          // console.log(file)
          //         file.awsObject = f;
          //         dispatch({type: UPDATE_SCAN_FILE, payload: {file}})
          //     })
          // })

          // var loc = 'https://dcchail.s3.amazonaws.com/trans-videos/RightRail.mp4.m3u8'
          // AwsCloudfrontService.getObjectURL_test(loc)
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });

        // dispatch({type: GET_SCAN, payload: data})
        // data.Files.forEach(file => {
        //     AwsGetImageService.getObjectURL(file.location, (f) => {
        //         file.awsObject = f;
        //         dispatch({type: UPDATE_SCAN_FILE, payload: {file}})
        //     })
        // })

        // data.Snapshots.forEach(file => {
        //     AwsGetImageService.getObjectURL(file.location, (f) => {
        //         file.awsObject = f;
        //         dispatch({type: UPDATE_SNAPSHOT_FILE, payload: {file}})
        //     })
        // })
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const getScanExt = (id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scan/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_SCAN, payload: response.data });

          response.data.Files.forEach((file) => {
            if (file.type !== 'video') {
              AwsGetImageService.getObjectURL(file.location, (f) => {
                file.awsObject = f;
                // console.log(file)
                dispatch({ type: UPDATE_SCAN_FILE, payload: { file } });
              });
            } else {
              dispatch({ type: UPDATE_SCAN_FILE, payload: { file } });
            }
          });

          response.data.Snapshots.forEach((file) => {
            AwsGetImageService.getObjectURL(file.location, (f) => {
              file.awsObject = f;
              dispatch({ type: UPDATE_SNAPSHOT_FILE, payload: { file } });
              // dispatch({type: SNAPSHOT_FILES, payload: {file}})
            });
          });

          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });

        // dispatch({type: GET_SCAN, payload: data})
        // data.Files.forEach(file => {
        //     AwsGetImageService.getObjectURL(file.location, (f) => {
        //         file.awsObject = f;
        //         dispatch({type: UPDATE_SCAN_FILE, payload: {file}})
        //     })
        // })

        // data.Snapshots.forEach(file => {
        //     AwsGetImageService.getObjectURL(file.location, (f) => {
        //         file.awsObject = f;
        //         dispatch({type: UPDATE_SNAPSHOT_FILE, payload: {file}})
        //     })
        // })
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const saveSnapShot = (image, id, intl) => {
  return (dispatch) => {
    axios
      .post(`${ROOT_URL}/scans/snapshot/${id}`, image)
      .then((response) => {
        if (response.status === 200) {
          var file = response.data;
          AwsGetImageService.getObjectURL(file.location, (f) => {
            file.awsObject = f;
            dispatch({ type: GET_SNAPSHOT, payload: file });
          });
          dispatch({ type: INLINE_LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: INLINE_LOADING, payload: false });
      });
  };
};

export const addFollowers = (followers, id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .put(`${ROOT_URL}/scans/${id}`, { followers })
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonSuccess),
            container: 'top-right',
            type: 'success',
            ...notification,
          });
          dispatch(getScan(id, intl));
          dispatch({ type: LOADING, payload: false });
        } else {
          store.addNotification({
            title: intl.formatMessage(messages.errorTitle),
            message: intl.formatMessage(messages.commonError),
            type: 'warning',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        // console.log("error", response)
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const sendEmail = (id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/scans/${id}/mails`)
      .then((response) => {
        if (response.status === 200) {
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.successSentMail),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const deleteScan = (data, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .delete(`${ROOT_URL}/scans/${data.id}`)
      .then((response) => {
        if (response.status === 200) {
          let rqst = '&limit=10&offset=0';
          dispatch(getScans(rqst, intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonDeleteSuccess),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const editScan = (data, id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .put(`${ROOT_URL}/scans/${id}`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getCompanies('', intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonEditSuccess),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};
