import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { getCarsSelector, getCarsCountSelector } from "../../selectors/carsSelector"
import { getCars } from "../../actions/carActions"
import Table from "../../components/Table"
import messages from "./languages"
import qs from "query-string"
import commonmessages from "../commonLanguages"
import { FormattedMessage, injectIntl } from 'react-intl';

const CarPage = ({location, intl, history}) => {
    const dispatch = useDispatch();
    const [currentPage, setcurrentPage] = useState(1)
    const [limit, setlimit] = useState(10)
    const cars = useSelector(getCarsSelector)
    const carsCount = useSelector(getCarsCountSelector)
    
    const mountFunction = () => {
        window.scrollTo(0,0)
        let l = location;
        let search = qs.parse(l.search);

        if (search.offset && search.limit) {
            setlimit(search.limit)
            let currentPage = (search.offset / search.limit) === 0 ? 1 : (search.offset / search.limit)
            setcurrentPage(currentPage)
        } else {
            search.offset = 0;
            search.limit = 10;
        }

        search = qs.stringify(search);
        dispatch(push(`${location.pathname}?${search}`));
        dispatch(getCars(search, intl))
    }

    useEffect(mountFunction, [location.search])

    const fields = [
        "brand",
        "model",
        "licensePlate",
        "createdAt"
    ]

    const textfields = [
        intl.formatMessage(commonmessages.brandString),
        intl.formatMessage(commonmessages.modelString),
        intl.formatMessage(commonmessages.licensePlateString),
        intl.formatMessage(commonmessages.createdAtString),
    ]

    const pageChange = (page) => {
        let l = location
        let search = qs.parse(l.search)
        setcurrentPage(page);
        search.offset = (page * search.limit) - search.limit
        l.search = qs.stringify(search)
        dispatch(push(`${location.pathname}?${l.search}`))
        dispatch(getCars(l.search, intl))
    }
    
    return (
        <div className="page-wrapper h-100">
            <div className="container-fluid">
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">
                            <FormattedMessage {...messages.title} />
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center text-right d-none d-md-block">
                    </div>
                    <div className="">
                    </div>
                </div>
                <Table 
                    fields={fields}
                    dataArr={cars}
                    count={carsCount}
                    tableHeading={intl.formatMessage(messages.projectsString)}
                    pageChange={pageChange}
                    currentPage={currentPage}
                    limit={limit}
                    textfields={textfields}
                />
            </div>
        </div>
    )
}

export default injectIntl(CarPage);