import React from 'react';
import Modal from 'react-modal';
import { Field, reduxForm } from 'redux-form/immutable'
import Switch from 'react-switch';
import Immutable from "immutable"

import { FormattedMessage, injectIntl } from 'react-intl';

import { otherModalStyles } from "../../../constants"

const EditProjectForm = ({initialValues, dents, companies, countries, scanners, insurances, openModal, closeModal, handleSubmit, messages, intl, editProject}) => {
  
  const submitF = (data) => {
    data = data.toJS();
    let obj = {
      city: data.city,
      companyId: data.companyId.value,
      country: data.country.value,
      dentSizeMapperId: data.dentSizeMapperId.value,
      isEnabled: data.isEnabled,
      name: data.name,
      pdrMaxIntegration: data.pdrMaxIntegration
    };
    editProject(obj)
  }
  
  return (
    <Modal
      isOpen={openModal}
      contentLabel="Edit project"
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={otherModalStyles}>
      <div>
        
        <div className="card-header bg-info">
            <h4 className="m-b-0 text-white"><FormattedMessage {...messages.displayProjecttitle} /></h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(submitF)}>
            <div className="form-body">
              {/* <h3 className="card-title"><FormattedMessage {...messages.newProjectSubtitle} /></h3> */}
              <hr></hr>
              <div className="row p-t-20">
                <Field name="name" label={intl.formatMessage(messages.nameLabel)} component={renderField} errorText={intl.formatMessage(messages.errorText)} palceholder={intl.formatMessage(messages.namePlaceholder)} type="text" />
                <Field name="city" label={intl.formatMessage(messages.cityLabel)} component={renderField} errorText={intl.formatMessage(messages.errorText)} palceholder={intl.formatMessage(messages.cityPlaceholder)} type="text" />
                <Field label={intl.formatMessage(messages.countryLabel)} options={countries.map(c => {
                  return {label: c.name, value: c.name}
                })} name="country" component={renderSelect} errorText={intl.formatMessage(messages.errorText)} placeholder={intl.formatMessage(messages.countryPlaceholder)} />
                <Field label={intl.formatMessage(messages.enabledLabel)} name="isEnabled" component={renderSwitch} errorText={intl.formatMessage(messages.errorText)} type="text" />
                <Field label={intl.formatMessage(messages.pdrLabel)} name="pdrMaxIntegration" component={renderSwitch} errorText={intl.formatMessage(messages.errorText)} type="text" />
                <Field label={intl.formatMessage(messages.companyLabel)} options={companies.map(c => {
                  return {label: c.name, value: c.id}
                })} name="companyId" component={renderSelect} errorText={intl.formatMessage(messages.errorText)} placeholder={intl.formatMessage(messages.companyPlaceholder)} />
                <Field label={intl.formatMessage(messages.dentLabel)} options={dents.map(c => {
                  return {label: c.name, value: c.id}
                })} name="dentSizeMapperId" component={renderSelect} errorText={intl.formatMessage(messages.errorText)} placeholder={intl.formatMessage(messages.dentPlaceholder)} />
                <Field label={intl.formatMessage(messages.scannerLabel)} options={scanners.map(c => {
                  return {label: c.name, value: c.id}
                })} name="Scanners" multi={true} component={renderMultiField} errorText={intl.formatMessage(messages.errorText)} placeholder={intl.formatMessage(messages.scannerPlaceholder)} />
                <Field label={intl.formatMessage(messages.insuranceLabel)} options={insurances.map(c => {
                  return {label: c.name, value: c.id}
                })} name="Insurances" multi={true} component={renderMultiField} errorText={intl.formatMessage(messages.errorText)} placeholder={intl.formatMessage(messages.insurancesPlaceholder)} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}
const renderField = ({input, label, palceholder, errorText, warningText, type, meta: { touched, error, warning, valid }}) => (
  <div className="col-md-6">
    <div className={`form-group ${touched && error && "has-danger"}`}>
      <label className="control-label custom-label">{label}</label>
      <p className="">{input.value}</p>
      {/* <input disabled={true} type={type} {...input} className="form-control" placeholder={palceholder} /> */}
      {touched && error && <span className="form-control-feedback">{errorText}</span>}
    </div>
  </div>
)
const renderMultiField = ({input, label, palceholder, errorText, warningText, type, meta: { touched, error, warning, valid }}) => {
  if (Immutable.Iterable.isIterable(input.value)) {
    input.value = input.value.toJS();
  }
  return (
    <div className="col-md-6">
      <div className={`form-group ${touched && error && "has-danger"}`}>
        <label className="control-label custom-label">{label}</label>
        <p className="">{input.value !== "" && input.value.map(v => {return `${v.name}`}).join(', ')}
        </p>
        {touched && error && <span className="form-control-feedback">{errorText}</span>}
      </div>
    </div>
  )
}

const renderSelect = ({input, placeholder, options, label, palceholder, errorText, warningText, type, meta: { touched, error, warning, valid }}) => {
    if (Immutable.Iterable.isIterable(input.value)) {
        input.value = input.value.toJS();
    }
    return (
        <div className="col-md-6">
            <div className={`form-group ${touched && error && "has-danger"}`}>
                <label className="control-label custom-label">{label}</label>
                <p className="">{input.value.label}</p>
            {touched && error && <span className="form-control-feedback">{errorText}</span>}
            </div>
        </div>
    )
}

const renderSwitch = ({input, label, placeholder, divClass, type, meta: {touched, warning, error}}) => {
  return (
    <div className="col-lg-3">
      <label className="control-label custom-label">{label}</label><br/>
      <Switch disabled={true} onChange={() => input.onBlur(!input.value)} {...input} checked={input.value} className="" />
    </div>
  );
};

const validate = (values) => {
    values = values.toJS();
    let errors = {};
    
    if (!values.name) {
        errors.name = true
    }

    if (!values.city) {
        errors.city = true
    }

    if (!values.country) {
        errors.country = true
    }

    if (!values.companyId) {
        errors.companyId = true
    }

    if (!values.dentSizeMapperId) {
        errors.dentSizeMapperId = true
    }
    
    return errors
}

export default reduxForm({
    validate,
    enableReinitialize : true,
    form: 'EditProjectForm'
})(injectIntl(EditProjectForm));