
export var ROOT_URL;
export var HOME_URL;

if (process.env.NODE_ENV === 'development') {
  HOME_URL = "http://localhost:3000"
  ROOT_URL = "https://api.dcchailscan.com"
} else {
  HOME_URL = "https://portal.dcchailscan.com"
  ROOT_URL = "https://api.dcchailscan.com"
}

export const AWS_COGNITO_ID = 'us-east-2:42ef75bb-00a0-4608-adba-53832debf2a0';
export const AWS_ACCESS_KEY = "AKIAZ5PVQJMCTCJSFEGY"
export const AWS_SECRET_KEY = "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"

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_ERROR = "LOGIN_ERROR"
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const GET_USERS = "GET_USERS"
export const GET_PROJECTS = "GET_PROJECTS"
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_DENTS = "GET_DENTS";
export const ADD_PROJECT = "ADD_PROJECT"
export const GET_PROJECT = "GET_PROJECT"
export const GET_SCANNERS = "GET_SCANNERS"
export const GET_INSURANCES = "GET_INSURANCES"
export const GET_SEARCH = "GET_SEARCH"
export const SET_IS_SEARCHING = "SET_IS_SEARCHING"
export const GET_CARS = "GET_CARS"
export const GET_COLORS = "GET_COLORS";
export const GET_USER_GROUPS = "GET_USER_GROUPS"
export const GET_STATISTICS = "GET_STATISTICS"
export const GET_STATISTICS_COUNT = "GET_STATISTICS_COUNT"
export const TOGGLE_SEARCH = "TOGGLE_SEARCH"
export const GET_SCANS = "GET_SCANS"
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_SCAN = "GET_SCAN";
export const LOADING = "LOADING";
export const INLINE_LOADING = "INLINE_LOADING";
export const GET_SNAPSHOT = 'GET_SNAPSHOT'
export const UPDATE_SCAN_FILE = "UPDATE_SCAN_FILE"
export const UPDATE_SNAPSHOT_FILE = "UPDATE_SNAPSHOT_FILE";
export const UPDATE_SNAPSHOT_FILES = "UPDATE_SNAPSHOT_FILES";
export const UPDATE_USER_IMAGE = "UPDATE_USER_IMAGE";
export const SNAPSHOT_FILES = "SNAPSHOT_FILES";
export const RESSET_PASS_SUCCESS = "RESSET_PASS_SUCCESS";

export const notification = {
    container: 'top-right',                // where to position the notifications
    animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
    animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
    dismiss: {
        duration: 3000 
    }
}

export const otherModalStyles = {
    content : {
      display               : 'block',
      opacity               : 1,
      overflow 			        : 'auto',
      padding:                0,
      zIndex                : 1
    }
  }

export const videoModalStyle = {
  overlay : {
    backgroundColor       : 'rgba(255, 255, 255, 0.96)',
    display               : 'flex',
    justifyContent        : 'center',
    alignItems            : 'center',
  },
  content : {
    position              : 'relative',
    display               : 'flex',
    justifyContent        : 'center',
    alignItems            : 'center',
    opacity               : 1,
    overflow 			        : 'auto',
    padding:                0,
    zIndex                : 1,
    backgroundColor       : 'transparent',
    border                : 'none',
    top                   : 0,
    bottom                   : 0,
    left                   : 0,
    right                   : 0
  }
};