import { defineMessages } from 'react-intl';

export const scope = 'app.components.Cars';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seznam avtov'
  },
  searchString: {
    id: `${scope}.searchString`,
    defaultMessage: 'Vpišite iskalni niz'
  },
  searchButton: {
    id: `${scope}.searchButton`,
    defaultMessage: 'Išči'
  },
  projectsString: {
    id: `${scope}.projectsString`,
    defaultMessage: 'Avte'
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`,
  },
  postErrorText: {
    id: `${scope}.postErrorText`,
    defaultMessage: `Obvezno polje`,
  }
});