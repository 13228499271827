/*
 *
 * LanguageProvider reducer
 *
 */
import Immutable from "immutable"

import { CHANGE_LOCALE } from './constants';
import { DEFAULT_LOCALE } from '../../i18n';

const INITIAL_STATE = Immutable.fromJS({locale: DEFAULT_LOCALE});

export default function (state = INITIAL_STATE, action) {
	let nextState = state.toJS();

	switch (action.type)
  {
		case CHANGE_LOCALE:
      nextState.locale = action.locale;
      break;
    default:
      
    break;
  }
	return state.merge(nextState);
}
