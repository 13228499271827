import React from 'react';
import { useSelector } from "react-redux"
import { Route, Redirect } from 'react-router';
import { getUserData } from "../selectors/userSelector"

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const currentUser = useSelector(getUserData)
    
    return (
        <Route {...rest} render={props => {

            if (!currentUser.isAuthenticated) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            }

            if (currentUser.userGroupName === 'Insurance' && rest.path.split('/')[1] !== 'scans' ) {

                // restricted access for role Insurance so redirect to scans page
                return <Redirect to={{ pathname: '/scans', state: { from: props.location } }} />
            }

            // check if route is restricted by role
            // if (roles && roles.indexOf(currentUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/'}} />
            // }

            // authorised so return component
            return <Component {...props} />
        }} />
    )
}