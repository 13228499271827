import Immutable from 'immutable';
import { TOGGLE_SIDEBAR, SET_IS_SEARCHING, GET_SEARCH, TOGGLE_SEARCH, LOADING, INLINE_LOADING } from '../constants';

const INITIAL_STATE = Immutable.fromJS({toggleSidebar: false, isSearching: false, searchResults: {}, openSearch: false, loading_array: [], loading: false, i_loading_array: [], i_loading: false});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case TOGGLE_SIDEBAR:
			nextState.toggleSidebar = !nextState.toggleSidebar
        break;
		case SET_IS_SEARCHING:
			nextState.isSearching = action.payload
		break;
		case GET_SEARCH:
			nextState.searchResults = action.payload
		break;
		case TOGGLE_SEARCH:
			nextState.openSearch = !nextState.openSearch
		break;
		case LOADING:
			if (action.payload) {
                nextState.loading_array.push(true)
            } else {
                nextState.loading_array.pop();
            }
            if (nextState.loading_array.length === 0) {
                nextState.loading = false
            } else {
                nextState.loading = true
            }
		break;
		case INLINE_LOADING:
			if (action.payload) {
				nextState.i_loading_array.push(true)
			} else {
				nextState.i_loading_array.pop();
			}
			if (nextState.i_loading_array.length === 0) {
				nextState.i_loading = false
			} else {
				nextState.i_loading = true
			}
		break;
        default:

        break;
	}

	return state.merge(nextState);
}
