import Immutable from 'immutable';
import { GET_SCANNERS } from '../constants';

const INITIAL_STATE = Immutable.fromJS({scanners: [], scannersCount: 0});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_SCANNERS:
            nextState.scanners = action.payload.rows
            nextState.scannersCount = action.payload.count
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
