import axios from "axios"
import { store } from 'react-notifications-component';
import messages from './messages'
import { push } from 'connected-react-router'
import { ROOT_URL, LOGIN_USER, LOGOUT_USER, notification, HOME_URL, LOADING } from "../constants"

export const login = (data, intl) => {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/auth/login`, data)
		.then((response) => {
            if (response.status === 200) {
                response.data = {...response.data, token: `Bearer ${response.data.token}`};
                response.data = {...response.data, isAuthenticated: true};
                dispatch({type: LOGIN_USER, payload: response.data})
                axios.defaults.headers.common['Authorization'] = response.data.token;
                axios.defaults.headers.common['userGroupName'] = response.data.userGroupName;
                if (response.data.userGroupName === "User" || response.data.userGroupName === "Insurance") {
                    window.location.href = `${HOME_URL}/scans`;
                } else {
                    window.location.href = HOME_URL;
                }
            }
        })
        .catch(({response}) => {
            dispatch({type: LOADING, payload: false})
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.loginError),
                type: 'warning',
                ...notification
            })
        })
    }
}

export const logout = (intl) => {
    return (dispatch) => {
        dispatch({type: LOGOUT_USER, payload: {}})
        dispatch(push("/login"))
    }
}

export const forgotPassword = (username, intl) => {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/auth/resetPassword`, {email: username})
		.then((response) => {
            if (response.status === 200) {
                window.confirm("Check your email for reset password link.")
            }
        })
        .catch(({response}) => {
            dispatch({type: LOADING, payload: false})
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
        })
    }
}

export const resetPassword = (password, token, intl) => {
    return (dispatch) => {
        axios.post(`${ROOT_URL}/auth/resetPasswordCb?token=${token}`, {password})
		.then((response) => {
            if (response.status === 200) {
                store.addNotification({
                    title: "Success",
                    message: "Successfuly restarted password",
                    type: 'success',
                    ...notification
                })
            }
        })
        .catch(({response}) => {
            dispatch({type: LOADING, payload: false})
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
        })
    }
}