import { defineMessages } from 'react-intl';

export const scope = 'app.components.Projects';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seznam projektov'
  },
  searchString: {
    id: `${scope}.searchString`,
    defaultMessage: 'Vpišite iskalni niz'
  },
  searchButton: {
    id: `${scope}.searchButton`,
    defaultMessage: 'Išči'
  },
  projectsString: {
    id: `${scope}.projectsString`,
    defaultMessage: 'Projekte'
  },
  newProject: {
    id: `${scope}.newProject`,
    defaultMessage: 'Dodaj novi'
  },
  mtitle: {
    id: `${scope}.mtitle`,
    defaultMessage: 'New project'
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`,
  },
  postErrorText: {
    id: `${scope}.postErrorText`,
    defaultMessage: `Obvezno polje`,
  },
  newProjectButton: {
    id: `${scope}.newProjectButton`,
    defaultMessage: `Dodaj`,
  },
  namePlaceholder: {
    id: `${scope}.namePlaceholder`,
    defaultMessage: `Vpišite ime`
  },
  cityPlaceholder: {
    id: `${scope}.cityPlaceholder`,
    defaultMessage: `Vpišite mesto`
  },
  countryPlaceholder: {
    id: `${scope}.countryPlaceholder`,
    defaultMessage: `Vpišite državo`
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: `Ime`
  },
  cityLabel: {
    id: `${scope}.cityLabel`,
    defaultMessage: `Mesto`
  },
  countryLabel: {
    id: `${scope}.countryLabel`,
    defaultMessage: `Država`
  },
  enabledLabel: {
    id: `${scope}.enabledLabel`,
    defaultMessage: `Omogočen`
  },
  pdrLabel: {
    id: `${scope}.pdrLabel`,
    defaultMessage: `PDR Integracija`
  },
  companyLabel: {
    id: `${scope}.companyLabel`,
    defaultMessage: `Podjetje`
  },
  companyPlaceholder: {
    id: `${scope}.companyPlaceholder`,
    defaultMessage: `Izberite podjetje`
  },
  Subtitle: {
    id: `${scope}.Subtitle`,
    defaultMessage: `Add data`
  },
  dentLabel: {
    id: `${scope}.dentLabel`,
    defaultMessage: `Dent size mapper`
  },
  dentPlaceholder: {
    id: `${scope}.dentPlaceholder`,
    defaultMessage: `Izberite dent size mapper`
  },
  deleteString: {
    id: `${scope}.deleteString`,
    defaultMessage: `Ali ste sigurni da hočete zbrisat?`
  },
  displayProjecttitle: {
    id: `${scope}.displayProjecttitle`,
    defaultMessage: `Detatlje projekta`
  },
  scannerLabel: {
    id: `${scope}.scannerLabel`,
    defaultMessage: `Skenerje`
  }, 
  scannerPlaceholder: {
    id: `${scope}.scannerPlaceholder`,
    defaultMessage: `Izberite skenerje`
  },
  insuranceLabel: {
    id: `${scope}.insuranceLabel`,
    defaultMessage: `Zavaraovanja`
  },
  insurancesPlaceholder: {
    id: `${scope}.insurancesPlaceholder`,
    defaultMessage: `Izberite zavarovanje`
  }
});