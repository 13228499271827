import { AWS_COGNITO_ID, AWS_ACCESS_KEY, AWS_SECRET_KEY } from "../constants/index.js"

class AwsGetImageService {
    constructor(props) {
        this.albumBucketName = 'dcchail';
        window.AWS.config.region = 'us-east-2'; // Region
        window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
            IdentityPoolId: AWS_COGNITO_ID,
        });
        window.AWS.config.update({
            accessKeyId: AWS_ACCESS_KEY, 
            secretAccessKey: AWS_SECRET_KEY,
            correctClockSkew: true
        });

        window.AWS.events.on('retry', function(response) {  
            if (response.error.name === 'RequestTimeTooSkewed') {
              console.error('User time is not correct. Handling error!');
              console.log('AWS systemClockOffset:', window.AWS.config.systemClockOffset);
              var serverTime = Date.parse(response.httpResponse.headers['date']);
              var timeNow = new Date().getTime();
              console.log('AWS timestamp:', new Date(serverTime));
              console.log('Browser timestamp:', new Date(timeNow));
              window.AWS.config.systemClockOffset = Math.abs(timeNow - serverTime);
              response.error.retryable = true;
              console.log('Setting systemClockOffset to:', window.AWS.config.systemClockOffset);
              console.log('Retrying uploading to S3 once more...');
            }
        });

        this.s3 = new window.AWS.S3({
            apiVersion: '2006-03-01',
            params: {Bucket: this.albumBucketName}
        });
    }

    parseLink = (link) => {
        link = decodeURIComponent(link)//link.replace(/%2F/g, "/")
        let parsedLink = link.split("/");
        if (parsedLink[4] !== "Videos" && parsedLink[4] !== "Snapshots") {
            return `${parsedLink[3]}/${parsedLink[4]}`;
        } else {
            return `${parsedLink[3]}/${parsedLink[4]}/${parsedLink[5]}`;
        }
    }

    getObjectImage = (link) => {
        let key = this.parseLink(link);
        this.s3.getObject({Key: key},function(err,file) {
            if (err) {
                return err;
            } else {
                console.log(file)
                return file;
            }
        });
    }

    getObjectURL (link, callback) {
        let key = this.parseLink(link);
        // console.log(key)
        this.s3.getObject({Key: key}, (err, file) => {
            // console.log(err, file)
            return callback(file)
        });
    }

    getObjectURLasync (link, callback) {
        let key = this.parseLink(link);
        console.log('async')

        const s3 = this.s3

        async function getObj (key, s3) {

            const obj = await s3.getObject({Key: key}, (err, file) => {
                console.log(err, file)
                return file
            });
            console.log(obj)
        }

        getObj(key, s3)
    }

    async getS3File (link) {
        let key = this.parseLink(link);

        const response = await this.s3.getObject({Key: key}).promise();


        return response; // your file as a string
    }

    async checkS3File (link) {
        let key = this.parseLink(link);

        var response = null

        try {
            response = await this.s3.headObject({Bucket: 'dcchailpublic', Key: key}).promise();
        } catch(err) {
            console.log('Stream source not found!')
        }


        return response; // your file as a string
    }


    putObject (params, callback) {
        this.s3.upload(params, function(err, data) {
            if (err) console.log(err, err.stack); // an error occurred
            else {
                return callback(data)
            }           // successful response
        });
    }
}

export default new AwsGetImageService();