import axios from "axios"
import { store } from 'react-notifications-component';
import messages from './messages'
import { ROOT_URL, notification, GET_COMPANIES, LOADING } from "../constants"

export const getCompanies = (data, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.get(`${ROOT_URL}/companies?${data}`)
		.then((response) => {
            if (response.status === 200) {
                dispatch({type: GET_COMPANIES, payload: response.data})
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}