export const getUserData = store => {
    return store.getIn(['auth', 'user'])
}

export const getUsersSelector = store => {
    return store.getIn(['user', 'users'])
}

export const getUsersCountSelector = store => {
    return store.getIn(['user', 'usersCount'])
}

export const getUserGroupsSelector = store => {
    return store.getIn(['user', 'userGroups'])
}