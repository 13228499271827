import React, { useState, useEffect } from "react";
import { injectIntl } from 'react-intl';
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { selectSearchResults } from "../../selectors/mainSelector"
import { searchCharacters, setSearchResult } from "../../actions/mainActions"
import useDebounce from '../use-debounce';

const Search = ({intl, messages, openSearch, toggleSearch}) => {
    const dispatch = useDispatch();

    var searchInput;

    const [searchTerm, setSearchTerm] = useState('');
    const results = useSelector(selectSearchResults)

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const mountFunction = () => {
        searchInput.focus();
    }

    useEffect(mountFunction, [])

    const dobounceFunction = () => {
        if (debouncedSearchTerm) {
            dispatch(searchCharacters(debouncedSearchTerm, intl))
        } else {
            dispatch(setSearchResult())
        }
    }

    useEffect(dobounceFunction, [debouncedSearchTerm]);

    const renderSingleResult = (result, index) => {
        if (results[result].length > 0)
            return (
                <li className="pb-2" key={index}>
                    <p>{result.toUpperCase()}</p>
                    <ul>
                        {results[result].map((r, i) => {
                            console.log(r)
                            let replaced;
                            if (r.name)
                                replaced = r.name.toUpperCase().replace(searchTerm.toUpperCase(), searchTerm.toUpperCase().bold())
                            else if(r.Car)
                                replaced = r.Car.name.toUpperCase().replace(searchTerm.toUpperCase(), searchTerm.toUpperCase().bold())
                            else
                                replaced = '...'
                            return (
                                <li className="cursor-pointer" key={i}>
                                    <Link to={result === "scans" ? `/${result}/${r.id}` : `/${result}?displayId=${r.id}`}>
                                        <p onClick={toggleSearch} id="close-search" dangerouslySetInnerHTML={{ __html: replaced}}></p>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            )
    }

    return (
        <form className="app-search" style={{display: openSearch ? "block" : "none"}}>
            <input ref={(input) => { searchInput = input; }}  type="text" onChange={e => setSearchTerm(e.target.value)} className="form-control" placeholder={intl.formatMessage(messages.searchPlaceholder)} /> 
            <span onClick={toggleSearch} className="srh-btn">
                <i className="ti-close" ></i>
            </span> 
            <div className="search-result-wrap pt-3 pb-3">
                <ul>
                    {Object.keys(results).map(renderSingleResult)}
                </ul>
            </div>
        </form>
    )
}

export default injectIntl(Search)