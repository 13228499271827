import Immutable from 'immutable';
import { GET_STATISTICS, GET_STATISTICS_COUNT } from '../constants';

const INITIAL_STATE = Immutable.fromJS({statistics: {datasets: [], labels:[]}, statisticsCount: {projectCount: 0, scanCount: 0, userCount: 0}});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_STATISTICS:
            nextState.statistics = action.payload
        break;
        case GET_STATISTICS_COUNT:
            nextState.statisticsCount = action.payload
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
