import Immutable from 'immutable';
import { GET_COMPANIES } from '../constants';

const INITIAL_STATE = Immutable.fromJS({companies: [], companiesCount: 0});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_COMPANIES:
            nextState.companies = action.payload.rows
            nextState.companiesCount = action.payload.count
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
