import { defineMessages } from 'react-intl';

export const scope = 'app.components.Login';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Prijavite se'
  },
  usernamePlaceholder: {
    id: `${scope}.usernamePlaceholder`,
    defaultMessage: 'Uporabniško ime'
  },
  passwordPlaceholder: {
    id: `${scope}.passwordPlaceholder`,
    defaultMessage: 'Geslo'
  },
  loginButton: {
    id: `${scope}.loginButton`,
    defaultMessage: 'Prijavi se'
  },
  rightsText: {
    id: `${scope}.rightsText`,
    defaultMessage: `${new Date().getFullYear()} All Rights Reserved. DCC Hail!`
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`
  },
  postErrorText: {
    id: `${scope}.postErrorText`,
    defaultMessage: `Obvezno polje`
  },
  forgotButton: {
    id: `${scope}.forgotButton`,
    defaultMessage: `Forgot password?`
  },
  sendEmailButton: {
    id: `${scope}.sendEmailButton`,
    defaultMessage: `Send email`
  },
  cancelbackButton: {
    id: `${scope}.cancelbackButton`,
    defaultMessage: `Back`
  },
  sendResetPassButton: {
    id: `${scope}.sendResetPassButton`,
    defaultMessage: `Reset password`
  },
  repeatPasswordPlaceholder: {
    id: `${scope}.repeatPasswordPlaceholder`,
    defaultMessage: `Repeat password`
  },
  passerrorText: {
    id: `${scope}.passerrorText`,
    defaultMessage: `Passwords do not match`
  }
});