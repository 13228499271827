import { defineMessages } from 'react-intl';

export const scope = 'app.components.Scans';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seznam scans'
  },
  scansString: {
    id: `${scope}.scansString`,
    defaultMessage: 'Scans'
  },
  deleteString: {
    id: `${scope}.deleteString`,
    defaultMessage: `Ali ste sigurni da hočete zbrisat?`
  },
  stitle: {
    id: `${scope}.stitle`,
    defaultMessage: `Detalje za scan`
  },
  carString: {
    id: `${scope}.carString`,
    defaultMessage: `Avto`
  },
  carBrandString: {
    id: `${scope}.carBrandString`,
    defaultMessage: `Znamka`
  },
  carModelString: {
    id: `${scope}.carModelString`,
    defaultMessage: `Model`
  },
  vinString: {
    id: `${scope}.vinString`,
    defaultMessage: `VIN`
  },
  doorCountString: {
    id: `${scope}.doorCountString`,
    defaultMessage: `Št. vrat`
  },
  customerString: {
    id: `${scope}.customerString`,
    defaultMessage: `Stranka`
  },
  countryString: {
    id: `${scope}.countryString`,
    defaultMessage: `Država`
  },
  nameString: {
    id: `${scope}.nameString`,
    defaultMessage: `Ime`
  },
  lastnameString: {
    id: `${scope}.lastnameString`,
    defaultMessage: `Priimek`
  },
  emailString: {
    id: `${scope}.emailString`,
    defaultMessage: `Email`
  },
  telephoneString: {
    id: `${scope}.telephoneString`,
    defaultMessage: `Telefon`
  },
  scanString: {
    id: `${scope}.scanString`,
    defaultMessage: `Scan`
  },
  createdAtString: {
    id: `${scope}.createdAtString`,
    defaultMessage: `Ustvarjen`
  },
  finishedDateString: {
    id: `${scope}.finishedDateString`,
    defaultMessage: `Končan`
  },
  licensePlateString: {
    id: `${scope}.licensePlateString`,
    defaultMessage: `Registerska tablica`
  },
  videoString: {
    id: `${scope}.videoString`,
    defaultMessage: `Video`
  },
  snapshotString: {
    id: `${scope}.snapshotString`,
    defaultMessage: `Take snapshot`
  },
  ssnapshotString: {
    id: `${scope}.ssnapshotString`,
    defaultMessage: `Snapshots`
  },
  followersString: {
    id: `${scope}.followersString`,
    defaultMessage: `Followers`
  },
  nofollowersString: {
    id: `${scope}.nofollowersString`,
    defaultMessage: `No followers added`
  },
  addFString: {
    id: `${scope}.addFString`,
    defaultMessage: `Followers`
  },
  followerPlaceholder: {
    id: `${scope}.followerPlaceholder`,
    defaultMessage: `Write follower email`
  },
  followerLabel: {
    id: `${scope}.followerLabel`,
    defaultMessage: `Follower email`
  },
  newFollowersString: {
    id: `${scope}.newFollowersString`,
    defaultMessage: `Add new followers`
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Mandatory field`
  },
  sendMailString: {
    id: `${scope}.sendMailString`,
    defaultMessage: `Send email`
  },
  reportString: {
    id: `${scope}.reportString`,
    defaultMessage: `Report`
  },
  noreportString: {
    id: `${scope}.noreportString`,
    defaultMessage: `No reports`
  },
  projectNameString: {
    id: `${scope}.projectNameString`,
    defaultMessage: `Project name`
  },
  companyLabel: {
    id: `${scope}.companyLabel`,
    defaultMessage: `Company`
  },
  exportScan: {
    id: `${scope}.exportScan`,
    defaultMessage: `Export`
  },
  exportScansSubtitle: {
    id: `${scope}.exportScansSubtitle`,
    defaultMessage: `Export scans for period`
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: `Period`
  },
  insuranceLabel: {
    id: `${scope}.insuranceLabel`,
    defaultMessage: `Insurance`
  },
  insurancesPlaceholder: {
    id: `${scope}.insurancesPlaceholder`,
    defaultMessage: `Choose Insurance`
  }

});