import Immutable from 'immutable';

import { GET_SCANS, GET_SCAN, GET_SNAPSHOT, UPDATE_SCAN_FILE, UPDATE_SNAPSHOT_FILE } from '../constants';

const INITIAL_STATE = Immutable.fromJS({scans: [], scansCount: 0, scan: {Snapshots: [], Car: {}, AnalysisResult: {}, Customer: {}, Followers: [], Files: []}});
// const INITIAL_STATE = Immutable.fromJS({scans: [], scansCount: 0, scan: {Car: {}, AnalysisResult: {}, Customer: {}, Followers: [], Files: []}});


export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_SCANS:
            nextState.scans = action.payload.rows
            nextState.scansCount = action.payload.count
        break;
        case GET_SCAN:
            nextState.scan = {...nextState.scan, ...action.payload}
            nextState.scan.Files = [];
            nextState.scan.Snapshots = [];
        break;
        case GET_SNAPSHOT:
            nextState.scan.Snapshots.push(action.payload)
        break;
        case UPDATE_SCAN_FILE:
            nextState.scan.Files.push(action.payload.file)
        break;
        case UPDATE_SNAPSHOT_FILE:
            nextState.scan.Snapshots.push(action.payload.file)
        break;
        default:

        break;
	}
	return state.merge(nextState);
}
