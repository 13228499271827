import { defineMessages } from 'react-intl';

export const scope = 'app.components.Insurances';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seznam zavarovanj'
  },
  searchString: {
    id: `${scope}.searchString`,
    defaultMessage: 'Vpišite iskalni niz'
  },
  searchButton: {
    id: `${scope}.searchButton`,
    defaultMessage: 'Išči'
  },
  insurancesString: {
    id: `${scope}.insurancesString`,
    defaultMessage: 'Zavarovanja'
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'Dodaj novi'
  },
  mtitle: {
    id: `${scope}.mtitle`,
    defaultMessage: 'New insurance'
  },
  etitle: {
    id: `${scope}.etitle`,
    defaultMessage: 'Edit insurance'
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`,
  },
  postErrorText: {
    id: `${scope}.postErrorText`,
    defaultMessage: `Obvezno polje`,
  },
  namePlaceholder: {
    id: `${scope}.namePlaceholder`,
    defaultMessage: `Vpišite ime`
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: `Ime`
  },
  Subtitle: {
    id: `${scope}.Subtitle`,
    defaultMessage: `Add data`
  },
  deleteString: {
    id: `${scope}.deleteString`,
    defaultMessage: `Ali ste sigurni da hočete zbrisat?`
  }
});