import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { v4 as uuid } from 'uuid';
import { saveSnapShot } from '../../actions/scansActions';
import { selectHistory, selectILoading } from '../../selectors/mainSelector';
import Video from '../VideoPlayer';

import { FormattedMessage, injectIntl } from 'react-intl';
import AwsGetImageService from '../../utils/awsImagesService';

// import { otherModalStyles } from "../../constants"
import { INLINE_LOADING, videoModalStyle } from '../../constants';

const VideoModal = ({
  initialValues,
  openModal,
  closeModal,
  handleSubmit,
  messages,
  intl,
  addNewFollowers,
  scan,
  match_id,
  video_link,
}) => {
  //const [fields, setFields] = useState([""])
  // const [videoValue, setVideoValue] = useState(null)
  const [videoLoaded, setVideoLoaded] = useState(false);
  const i_loading = useSelector(selectILoading);
  const location = useSelector(selectHistory);

  const uuidRegex = new RegExp(
    /scan\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    'i'
  );

  const dispatch = useDispatch();

  const videoState = (state) => {
    setVideoLoaded(state);
  };

  const makeSnapShot = () => {
    var video = document.getElementsByTagName('video')[0];
    var canvas = document.createElement('canvas');
    var w, h, ratio;
    ratio = video.videoWidth / video.videoHeight;
    w = video.videoWidth - 100;
    h = parseInt(w / ratio, 10);
    canvas.width = w;
    canvas.height = h;
    var context = canvas.getContext('2d');
    context.fillRect(0, 0, w, h);
    context.drawImage(video, 0, 0, w, h);

    let link = scan.dataPath.split(`\\`);
    let key = link[link.length - 1];

    canvas.toBlob((blob) => {
      dispatch({ type: INLINE_LOADING, payload: true });
      var name = uuid();
      var params = {
        Body: blob,
        Bucket: 'dcchail',
        Key: `${key}/Snapshots/${name}.png`,
        ServerSideEncryption: 'AES256',
        StorageClass: 'STANDARD',
      };
      AwsGetImageService.putObject(params, (result) => {
        let obj = {
          location: result.Location,
          id: name,
          name: `${name}.png`,
        };
        dispatch(saveSnapShot(obj, match_id, intl));
      });
    });
  };

  return (
    <Modal
      isOpen={openModal}
      // contentLabel="Add Followers"
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={videoModalStyle}
    >
      <div className="modal-body">
        <Video videoLink={video_link} videoState={videoState} />
        {videoLoaded && !uuidRegex.test(location.pathname) && (
          <div className="row mr-0 ml-0">
            <div className="col-md-12 text-right mt-2">
              {!i_loading ? (
                <button
                  disabled={i_loading}
                  onClick={makeSnapShot}
                  className="btn btn-success"
                >
                  <i className="fa fa-check"></i>{' '}
                  <FormattedMessage {...messages.snapshotString} />
                </button>
              ) : (
                <img
                  width="50"
                  src="../assets/images/loading-gif.gif"
                  alt="loading"
                />
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: 'AddFollowersForm',
})(injectIntl(VideoModal));
