import Immutable from 'immutable';
import { GET_USERS, GET_USER_GROUPS } from '../constants';

const INITIAL_STATE = Immutable.fromJS({users: [], usersCount: 0, userGroups: [], userGroupsCount: 0});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_USERS:
            nextState.users = action.payload.rows
            nextState.usersCount = action.payload.count
        break;
        case GET_USER_GROUPS:
            nextState.userGroups = action.payload.rows
            nextState.userGroupsCount = action.payload.count
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
