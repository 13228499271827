import Immutable from 'immutable';

import { SNAPSHOT_FILES } from '../constants';

const INITIAL_STATE = Immutable.fromJS({snapshots: []});


export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
        case SNAPSHOT_FILES:
            nextState.snapshots.push(action.payload.file)
        break;
        default:

        break;
	}
	return state.merge(nextState);
}
