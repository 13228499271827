import axios from 'axios';
import { store } from 'react-notifications-component';
import messages from './messages';
import { ROOT_URL, notification, GET_INSURANCES, LOADING } from '../constants';

export const getInsurances = (data, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .get(`${ROOT_URL}/insurances?${data}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: GET_INSURANCES, payload: response.data });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};
export const addNewInsurance = (data, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .post(`${ROOT_URL}/insurances`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getInsurances(undefined, intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonSuccess),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const deleteInsurance = (id, intl) => {
  return (dispatch) => {
    dispatch({ type: LOADING, payload: true });
    axios
      .delete(`${ROOT_URL}/insurances/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch(getInsurances(undefined, intl));
          store.addNotification({
            title: intl.formatMessage(messages.successTitle),
            message: intl.formatMessage(messages.commonDeleteSuccess),
            type: 'success',
            ...notification,
          });
          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          title: intl.formatMessage(messages.errorTitle),
          message: intl.formatMessage(messages.commonError),
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };
};
