import axios from "axios"
import { store } from 'react-notifications-component';
import messages from './messages'
import { ROOT_URL, notification, GET_SCANNERS, LOADING } from "../constants"

export const getScanners = (data, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.get(`${ROOT_URL}/scanners?${data}`)
		.then((response) => {
            if (response.status === 200) {
                dispatch({type: GET_SCANNERS, payload: response.data})
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}
export const addNewScanner = (data, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.post(`${ROOT_URL}/scanners`, data)
		.then((response) => {
            if (response.status === 200) {
                dispatch(getScanners(undefined, intl));
                store.addNotification({
                    title: intl.formatMessage(messages.successTitle),
                    message: intl.formatMessage(messages.commonSuccess),
                    type: 'success',
                    ...notification
                })
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}

export const deleteScanner = (id, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.delete(`${ROOT_URL}/scanners/${id}`)
		.then((response) => {
            if (response.status === 200) {
                dispatch(getScanners(undefined, intl));
                store.addNotification({
                    title: intl.formatMessage(messages.successTitle),
                    message: intl.formatMessage(messages.commonDeleteSuccess),
                    type: 'success',
                    ...notification
                })
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}