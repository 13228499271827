import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { getCompanySelector } from "../../../selectors/companySelector"
import { getInsurancesSelector } from "../../../selectors/insuranceSelector"
import { getUsersSelector, getUsersCountSelector, getUserGroupsSelector, getUserData } from "../../../selectors/userSelector"
import { getUsers, addUser, getUserGroups, editUser, deleteUser } from "../../../actions/userActions"
import { getCompanies } from "../../../actions/companyActions"
import { getInsurances } from "../../../actions/insurancesActions"
import Table from "../../../components/Table"
import NewUserModal from "./newUserModal"
import EditUserModal from "./editUserModal"
import commonmessages from "../../commonLanguages"
import Immutable from 'immutable';

import messages from "../languages"
import qs from "query-string"
import { FormattedMessage, injectIntl } from 'react-intl';

const AdminUsersPage = ({location, intl}) => {
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1)
  const [currentSearch, setcurrentSearch] = useState(false)
  const [openedAddModal, setopenedAddModal] = useState(false)
  const [openedEditModal, setopenedEditModal] = useState(false)
  const [thisuser, setthisuser] = useState(null)
  const [limit, setlimit] = useState(10)
  const companies = useSelector(getCompanySelector)
  const insurances = useSelector(getInsurancesSelector)
  const userGroups = useSelector(getUserGroupsSelector)
  const users = useSelector(getUsersSelector)
  const usersCount = useSelector(getUsersCountSelector)
  const user = useSelector(getUserData)
  const group = 'Admin'

  const mountFunction = () => {
    window.scrollTo(0,0)
    let l = location;
    let search = qs.parse(l.search);

    if (search.offset && search.limit) {
        setlimit(search.limit)
        let currentPage = (search.offset / search.limit) === 0 ? 1 : (search.offset / search.limit)
        setcurrentPage(currentPage)
    } else {
        search.offset = 0;
        search.limit = 10;
    }

    search.group = group

    setcurrentSearch(search);

    search = qs.stringify(search);
    dispatch(push(`${location.pathname}?${search}`));
    dispatch(getUsers(search, intl))
    if (user.userGroupName === "Super Admin")
      dispatch(getCompanies("", intl))
      dispatch(getInsurances("", intl))
    dispatch(getUserGroups("", intl))
  }

  useEffect(mountFunction, [location.search])

  const fields = [
    "username",
    "createdAt"
  ]

  const loadXHR = (url) => {
    return new Promise(function(resolve, reject) {
      try {
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.onerror = function() {reject("Network error.")};
          xhr.onload = function() {
              if (xhr.status === 200) {resolve(xhr.response)}
              else {reject("Loading error:" + xhr.statusText)}
          };
          xhr.send();
      }
      catch(err) {reject(err.message)}
    });
}

  const textfields = [
    intl.formatMessage(commonmessages.usernameString),
    intl.formatMessage(commonmessages.createdAtString)
  ]

  const editButtonAction = (user) => () => {
    
    
    let userGroup = userGroups.find(u => {
      return u.id === user.userGroupId
    })
    let company = companies.find(c => {
      return c.id === user.companyId
    })
    let insurance = insurances.find(c => {
      return c.id === user.insuranceId
    })
    user.userGroupId = userGroup ? { label: userGroup.name, value: userGroup.id } : {}
    user.companyId = company ? { label: company.name, value: company.id } : {}
    user.insuranceId = insurance ? { label: insurance.name, value: insurance.id } : {}
    
    setthisuser(user)
    setopenedEditModal(!openedAddModal)
  }

  const deleteButtonAction = (id) => () => {
    if (window.confirm(intl.formatMessage(messages.deleteString))) {
      let data = {};
      data.id = id;
      data.originPage = currentSearch;
      dispatch(deleteUser(data, intl))
    }
  }

  const openAddProjectModal = () => {
    setopenedAddModal(!openedAddModal)
  }

  const openEditProjectModal = () => {
    setopenedEditModal(!openedEditModal)
  }

  const _addUser = (data) => {
    dispatch(addUser(data, intl))
  }

  const _editUser = (data, flag) => {
    dispatch(editUser(data, thisuser.id, intl, flag))
  }

  const pageChange = (page) => {
      let l = location
      let search = qs.parse(l.search)
      setcurrentPage(page);
      search.offset = (page * search.limit) - search.limit

      search.group = group

      l.search = qs.stringify(search)
      dispatch(push(`${location.pathname}?${l.search}`))
      dispatch(getUsers(l.search, intl))
  }
  
  return (
    <div className="page-wrapper h-100">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h3 className="text-themecolor">
              <FormattedMessage {...messages.title} />
            </h3>
          </div>
          <div className="col-md-7 align-self-center text-right">
            <button onClick={openAddProjectModal} type="button" className="btn btn-info"><i className="fa fa-plus-circle"></i> <FormattedMessage {...messages.newUser} /></button>
          </div>
          <div className="">
          </div>
        </div>
        <Table 
          fields={fields}
          count={usersCount}
          dataArr={users}
          tableHeading={intl.formatMessage(messages.adminsUsergroupString)}
          editAction={editButtonAction}
          deleteAction={deleteButtonAction}
          pageChange={pageChange}
          currentPage={currentPage}
          limit={limit}
          textfields={textfields}
        />
        <NewUserModal 
          messages={messages} 
          closeModal={openAddProjectModal} 
          openModal={openedAddModal}
          companies={companies}
          insurances={insurances}
          userGroups={userGroups}
          loadXHR={loadXHR}
          addNew={_addUser}
          user={user}
          originPage={currentSearch}
        />
        <EditUserModal 
          initialValues={Immutable.fromJS(thisuser)}
          loadXHR={loadXHR}
          pimage={thisuser && thisuser.profileImage}
          messages={messages} 
          closeModal={openEditProjectModal} 
          openModal={openedEditModal}
          companies={companies}
          insurances={insurances}
          userGroups={userGroups}
          editData={_editUser}
          user={user}
          originPage={currentSearch}
        />
      </div>
    </div>
  )
}

export default injectIntl(AdminUsersPage);