import React from "react";
import { Field, reduxForm } from 'redux-form/immutable'

import { FormattedMessage, injectIntl } from 'react-intl';

const LoginForm = ({messages, handleSubmit, submitLogin, intl, handleResetPassword}) => {

    const submitF = (data) => {
        submitLogin(data.toJS())
    }
    
    return (
        <form className="form-horizontal form-material" onSubmit={handleSubmit(submitF)}>   
            <span href="#" className="text-center db m-t-40">
                <img width="240" src="../assets/images/dcchail.png" alt="Home" />
            </span>
            <Field className="m-t-40" name="username" component={renderField} errorText={intl.formatMessage(messages.errorText)} palceholder={intl.formatMessage(messages.usernamePlaceholder)} type="text" />
            <Field name="password" errorText={intl.formatMessage(messages.errorText)} component={renderField} type="password" palceholder={intl.formatMessage(messages.passwordPlaceholder)} />
            <div className="form-group text-center m-t-20">
                <div className="col-xs-12">
                    <p onClick={handleResetPassword} className="cursor-pointer"><FormattedMessage {...messages.forgotButton} /></p>
                </div>
            </div>
            <div className="form-group text-center m-t-20">
                <div className="col-xs-12">
                    <button className="btn btn-info btn-lg btn-block text-uppercase btn-rounded" type="submit"><FormattedMessage {...messages.loginButton} /></button>
                </div>
            </div>
        </form>
    )
}

const renderField = ({input, palceholder, errorText, warningText, type, meta: { touched, error, warning, valid }}) => (
    <div className="form-group m-t-40">
        <div className="col-xs-12">
            <input type={type} {...input} className="form-control" placeholder={palceholder} />
            {touched && ((error && <span className="text-danger pb-2">{errorText}</span>) || (warning && <span className="text-warning">{warningText}</span>))}
        </div>
    </div>
)

const validate = (values) => {
    values = values.toJS();
    let errors = {};
    
    if (!values.username) {
        errors.username = true
    }

    if (!values.password) {
        errors.password = true
    }

    return errors
}

export default reduxForm({
    validate,
    form: 'login'
})(injectIntl(LoginForm));