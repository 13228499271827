import React from "react";

const Loading = () => {

    return (
        <div className="preloader">
            <div className="loader">
                <div className="loader__figure"></div>
                <p className="loader__label">DCC Hail</p>
            </div>
        </div>
    )
}

export default Loading;