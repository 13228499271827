import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import { getInsurancesSelector, getInsurancesCountSelector } from "../../selectors/insuranceSelector"
import { getInsurances, addNewInsurance, deleteInsurance } from "../../actions/insurancesActions"
import { getUserData } from "../../selectors/userSelector"
import Table from "../../components/Table"
import messages from "./languages"
import qs from "query-string"
import commonmessages from "../commonLanguages"
import { FormattedMessage, injectIntl } from 'react-intl';
import CommonModal from "../../components/CommonModal"

const InsurancesPage = ({location, intl, history}) => {
    const dispatch = useDispatch();
    const [currentPage, setcurrentPage] = useState(1)
    const [openedNewModal, setopenedNewModal] = useState(false)
    const [limit, setlimit] = useState(10)

    const insurances = useSelector(getInsurancesSelector)
    const insurancesCount = useSelector(getInsurancesCountSelector)
    const user = useSelector(getUserData)


    const modalfields=[
        {name: "name", show: true, label: intl.formatMessage(messages.nameLabel), placeholder: intl.formatMessage(messages.namePlaceholder)}
    ]
    
    const mountFunction = () => {
        window.scrollTo(0,0)
        let l = location;
        let search = qs.parse(l.search);

        if (search.offset && search.limit) {
            setlimit(search.limit)
            let currentPage = (search.offset / search.limit) === 0 ? 1 : (search.offset / search.limit)
            setcurrentPage(currentPage)
        } else {
            search.offset = 0;
            search.limit = 10;
        }

        search = qs.stringify(search);
        dispatch(push(`${location.pathname}?${search}`));
        dispatch(getInsurances(search, intl))
    }

    useEffect(mountFunction, [location.search])

    const fields = [
        //"id",
        "name",
        "createdAt"
    ]

    const textfields = [
        //intl.formatMessage(commonmessages.idString),
        intl.formatMessage(commonmessages.nameString),
        intl.formatMessage(commonmessages.createdAtString),
    ]

    const deleteButtonAction = (id) => () => {
        if (window.confirm(intl.formatMessage(messages.deleteString))) {
            dispatch(deleteInsurance(id, intl))
        }
    }

    const openNewModal = () => {
        setopenedNewModal(!openedNewModal)
    }


    const _addNew = (data) => {
        var code = data.name.replace(/\s+/g, "_");
        data['code'] = code.toLowerCase();
        dispatch(addNewInsurance(data, intl));
        setopenedNewModal(false)
    }

    const pageChange = (page) => {
        let l = location
        let search = qs.parse(l.search)
        setcurrentPage(page);
        search.offset = (page * search.limit) - search.limit
        l.search = qs.stringify(search)
        dispatch(push(`${location.pathname}?${l.search}`))
        dispatch(getInsurances(l.search, intl))
    }
    
    return (
        <div className="page-wrapper h-100">
            <div className="container-fluid">
                <div className="row page-titles">
                    <div className="col-md-5 align-self-center">
                        <h3 className="text-themecolor">
                            <FormattedMessage {...messages.title} />
                        </h3>
                    </div>
                    <div className="col-md-7 align-self-center text-right">
                        {user.userGroupName && (user.userGroupName === 'Super Admin') && <button onClick={openNewModal} type="button" className="btn btn-info"><i className="fa fa-plus-circle"></i> <FormattedMessage {...messages.new} /></button>}
                    </div>
                    <div className="">
                    </div>
                </div>
                <Table 
                    fields={fields}
                    dataArr={insurances}
                    count={insurancesCount}
                    tableHeading={intl.formatMessage(messages.insurancesString)}
                    deleteAction={user.userGroupName && (user.userGroupName === 'Super Admin') && deleteButtonAction}
                    pageChange={pageChange}
                    currentPage={currentPage}
                    limit={limit}
                    textfields={textfields}
                />
            </div>
            {openedNewModal && <CommonModal 
                messages={messages} 
                form="NewInsuranceForm"
                fields={modalfields}
                closeModal={openNewModal} 
                openModal={openedNewModal}
                addNew={_addNew}
            />}
        </div>
    )
}

export default injectIntl(InsurancesPage);