import { push } from 'connected-react-router';
import qs from 'query-string';
import React, { useEffect, useState, useRef } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getScans, deleteScan } from '../../actions/scansActions';
import Table from '../../components/Table';
import { store } from 'react-notifications-component';
import ExportScanModal from './components/exportScanModal';
import {
  getScansCountSelector,
  getScansSelector,
} from '../../selectors/scansSelector';
import { getInsurancesSelector } from '../../selectors/insuranceSelector';
import { getUserData } from '../../selectors/userSelector';
import { getInsurances } from '../../actions/insurancesActions';
import { ROOT_URL, notification, LOADING } from '../../constants';
import commonmessages from '../commonLanguages';
import messages from './languages';
import axios from 'axios';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';

const ScansPage = ({ location, intl, history }) => {
  const dispatch = useDispatch();
  const [currentPage, setcurrentPage] = useState(1);
  const [currentSearch, setcurrentSearch] = useState(false);
  const [limit, setlimit] = useState(10);
  const [openedAddModal, setopenedAddModal] = useState(false);
  const [headers, setHeaders] = useState([]);
  const [exportData, setExportData] = useState([]);

  const user = useSelector(getUserData);
  const scans = useSelector(getScansSelector);
  const scansCount = useSelector(getScansCountSelector);
  const insurances = useSelector(getInsurancesSelector);

  const scanLinkElement = useRef(null);

  const mountFunction = () => {
    window.scrollTo(0, 0);
    let l = location;
    let search = qs.parse(l.search);
    search.sort = 'synchronizedDate-desc';
    if (search.offset && search.limit) {
      setlimit(search.limit);
      let currentPage =
        search.offset / search.limit === 0 ? 1 : search.offset / search.limit;
      setcurrentPage(currentPage);
    } else {
      search.offset = 0;
      search.limit = 10;
    }

    setcurrentSearch(search);

    search = qs.stringify(search);
    dispatch(push(`${location.pathname}?${search}`));
    dispatch(getScans(search, intl));
    dispatch(getInsurances('', intl));
  };

  useEffect(mountFunction, [location.search]);

  const fields = [
    'licensePlate',
    'projectName',
    'insuranceCode',
    'createdAt',
    'synchronizedDate',
  ];

  const textfields = [
    intl.formatMessage(commonmessages.licensePlateString),
    intl.formatMessage(commonmessages.projectNameString),
    intl.formatMessage(commonmessages.insuranceString),
    intl.formatMessage(commonmessages.createdAtString),
    intl.formatMessage(commonmessages.synchronizedAtString),
  ];

  const pageChange = (page) => {
    let l = location;
    let search = qs.parse(l.search);
    search.sort = 'synchronizedDate-desc';
    setcurrentPage(page);
    search.offset = page * search.limit - search.limit;
    l.search = qs.stringify(search);
    dispatch(push(`${location.pathname}?${l.search}`));
    dispatch(getScans(l.search, intl));
  };

  const openExportScansModal = () => {
    setopenedAddModal(!openedAddModal);
  };

  const _exportScans = (data) => {
    axios
      .get(`${ROOT_URL}/scans?${data}`)
      .then(async (response) => {
        if (response.status === 200) {
          var headers = [
            { label: 'Scan ID', key: 'scanID' },
            { label: 'Project', key: 'projectName' },
            { label: 'Country', key: 'country' },
            { label: 'City', key: 'city' },
            { label: 'Scan Date', key: 'scanDate' },
            { label: 'License Plate', key: 'licensePlate' },
            { label: 'Sync Date', key: 'syncDate' },
            { label: 'Exported', key: 'exported' },
          ];

          async function parseExportData(data) {
            return await data.map((scan) => {
              const filteredScanData = {};

              filteredScanData.scanID = scan.id;
              filteredScanData.projectName = scan.Project.name;
              filteredScanData.country = scan.Project.country;
              filteredScanData.city = scan.Project.city;
              filteredScanData.scanDate = moment(scan.scannedDate).format(
                'DD-MM-YYYY HH:MM'
              );
              filteredScanData.licensePlate = scan.Car.licensePlate;
              filteredScanData.syncDate = moment(scan.synchronizedDate).format(
                'DD-MM-YYYY HH:MM'
              );
              filteredScanData.exported = scan.exported ? 'yes' : '';

              return filteredScanData;
            });
          }

          await setHeaders(headers);
          let parsedData = await parseExportData(response.data.rows);
          await setExportData(parsedData);

          scanLinkElement.current.link.click();

          dispatch({ type: LOADING, payload: false });
        }
      })
      .catch(({ response }) => {
        console.log('error', response);
        store.addNotification({
          // title: intl.formatMessage(messages.errorTitle),
          title: 'Error',
          message: 'Error',
          type: 'warning',
          ...notification,
        });
        dispatch({ type: LOADING, payload: false });
      });
  };

  const displayButtonAction = (row) => () => {
    dispatch(push(`/scans/${row.id}`));
  };

  const deleteButtonAction = (id) => () => {
    if (window.confirm(intl.formatMessage(messages.deleteString))) {
      let data = {};
      data.id = id;
      data.originPage = currentSearch;
      dispatch(deleteScan(data, intl));
    }
  };

  return (
    <div className="page-wrapper h-100">
      <div className="container-fluid">
        <div className="row page-titles">
          <div className="col-md-5 align-self-center">
            <h3 className="text-themecolor">
              <FormattedMessage {...messages.title} />
            </h3>
          </div>
          <div className="col-md-7 align-self-center text-right d-none d-md-block">
            {user.userGroupName &&
              (user.userGroupName === 'Admin' ||
                user.userGroupName === 'Insurance') && (
                <button
                  onClick={() => openExportScansModal()}
                  type="button"
                  className="btn btn-info"
                >
                  {' '}
                  <FormattedMessage {...messages.exportScan} />
                </button>
              )}
            {user.userGroupName &&
              (user.userGroupName === 'Admin' ||
                user.userGroupName === 'Insurance') && (
                <CSVLink
                  headers={headers}
                  filename="Scans.csv"
                  data={exportData}
                  ref={scanLinkElement}
                />
              )}
          </div>
          <div className=""></div>
        </div>
        <Table
          fields={fields}
          dataArr={scans}
          count={scansCount}
          displayAction={displayButtonAction}
          tableHeading={intl.formatMessage(messages.scansString)}
          deleteAction={
            user.userGroupName && user.userGroupName === 'Admin'
              ? deleteButtonAction
              : undefined
          }
          pageChange={pageChange}
          currentPage={currentPage}
          limit={limit}
          textfields={textfields}
        />

        <ExportScanModal
          messages={messages}
          closeModal={openExportScansModal}
          openModal={openedAddModal}
          exportScans={_exportScans}
          insurances={insurances}
          user={user}
        />
      </div>
    </div>
  );
};

export default injectIntl(ScansPage);
