import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import LanguageProvider from './components/LanguageProvider';

import Immutable from 'immutable';
import configureStore from './utils/history'
import { translationMessages } from './i18n';

import './styles/index.css';
import './styles/bootstrap.css';
// import './styles/font-awesome.css';
import './styles/custom.css';
import './styles/colors/red-dark.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


const initialState = Immutable.Map();
const MOUNT_NODE = document.getElementById('root');

const store = configureStore(initialState)

const render = messages => {
  ReactDOM.render (
    <Provider store={store}>
      <LanguageProvider messages={messages}>
          <App />
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
