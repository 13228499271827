import { defineMessages } from 'react-intl';

export const scope = 'app.components.Dashboard';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Dashboard'
  },
  searchString: {
    id: `${scope}.searchString`,
    defaultMessage: 'Vpišite iskalni niz'
  },
  searchButton: {
    id: `${scope}.searchButton`,
    defaultMessage: 'Išči'
  },
  projectsString: {
    id: `${scope}.projectsString`,
    defaultMessage: 'Avte'
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`,
  },
  postErrorText: {
    id: `${scope}.postErrorText`,
    defaultMessage: `Obvezno polje`,
  },
  usersText: {
    id: `${scope}.usersText`,
    defaultMessage: `Uporbanike`,
  },
  projectsText: {
    id: `${scope}.projectsText`,
    defaultMessage: `Projekte`,
  },
  scansText: {
    id: `${scope}.scansText`,
    defaultMessage: `Scans`,
  },
  scannersLabel: {
    id: `${scope}.scannersLabel`,
    defaultMessage: `Izberite scanners`,
  },
  projectsLabel: {
    id: `${scope}.projectsLabel`,
    defaultMessage: `Izberite projekte`,
  }
});