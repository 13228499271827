import axios from "axios"
import { store } from 'react-notifications-component';
import messages from './messages'
import { ROOT_URL, notification, GET_PROJECTS, GET_PROJECT, LOADING } from "../constants"

export const getProjects = (data="limit=10&offset=0", intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.get(`${ROOT_URL}/projects?${data}`)
		.then((response) => {
            if (response.status === 200) {
                dispatch({type: GET_PROJECTS, payload: response.data})
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}

export const addNewProject = (data, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})   
        axios.post(`${ROOT_URL}/projects`, data)
		.then((response) => {
            if (response.status === 201) {
                dispatch(getProjects(undefined, intl));
                store.addNotification({
                    title: intl.formatMessage(messages.successTitle),
                    message: intl.formatMessage(messages.commonSuccess),
                    type: 'success',
                    ...notification
                })
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}

export const editProject = (data, id, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.put(`${ROOT_URL}/projects/${id}`, data)
		.then((response) => {
            if (response.status === 200) {
                dispatch(getProjects(undefined, intl));
                store.addNotification({
                    title: intl.formatMessage(messages.successTitle),
                    message: intl.formatMessage(messages.commonEditSuccess),
                    type: 'success',
                    ...notification
                })
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}

export const getProject = (id, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})
        axios.get(`${ROOT_URL}/projects/${id}`)
		.then((response) => {
            if (response.status === 200) {
                dispatch({type: GET_PROJECT, payload: response.data})
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}

export const deleteProject = (id, intl) => {
    return (dispatch) => {
        dispatch({type: LOADING, payload: true})   
        axios.delete(`${ROOT_URL}/projects/${id}`)
		.then((response) => {
            if (response.status === 204) {
                dispatch(getProjects(undefined, intl));
                store.addNotification({
                    title: intl.formatMessage(messages.successTitle),
                    message: intl.formatMessage(messages.commonDeleteSuccess),
                    type: 'success',
                    ...notification
                })
                dispatch({type: LOADING, payload: false})
            }
        })
        .catch(({response}) => {
            console.log("error", response)
            store.addNotification({
                title: intl.formatMessage(messages.errorTitle),
                message: intl.formatMessage(messages.commonError),
                type: 'warning',
                ...notification
            })
            dispatch({type: LOADING, payload: false})
        })
    }
}