import React from 'react';
import axios from "axios"
import Routes from './Routes.js';
import { useSelector } from "react-redux"
import { getUserData } from "./selectors/userSelector"
import { getSidebarToggle, selectLoading, selectHistory } from "./selectors/mainSelector"
import ReactNotifications from 'react-notifications-component';
import Loading from "./components/Loading"
import 'react-notifications-component/dist/theme.css';
import 'animate.css';

const App = () => {
    const user = useSelector(getUserData)
    const sidebarOpen = useSelector(getSidebarToggle)
    const loading = useSelector(selectLoading)
    const location = useSelector(selectHistory);

    const uuidRegex = new RegExp(/scan\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/, 'i')

    
    if (user.isAuthenticated)
    {
        axios.defaults.headers.common['Authorization'] = user.token;
        axios.defaults.headers.common['userGroupName'] = user.userGroupName;

    }
    else 
        axios.defaults.headers.common['Authorization'] = null;

    return (
        <div className={`${uuidRegex.test(location.pathname) ? '' : 'fix-header fix-sidebar'} ${sidebarOpen ? 'mini-sidebar' : ''}`} id="main-wrapper">
            {loading && <Loading />}
            <Routes />
            <ReactNotifications />
        </div>
    );
}

export default App;
