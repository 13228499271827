import Immutable from 'immutable';
import { GET_DENTS } from '../constants';

const INITIAL_STATE = Immutable.fromJS({dents: [], dentsCount: 0});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case GET_DENTS:
            nextState.dents = action.payload.rows
            nextState.dentsCount = action.payload.count
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
