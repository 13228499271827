import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import messages from "./languages"
import { push } from 'connected-react-router'
import { getScanners } from "../../actions/scannersActions"
import { getStatistics, getStatisticsCount } from "../../actions/dashboardActions"
import { getScannersSelector } from "../../selectors/scannerSelector"
import { getProjectsSelector } from "../../selectors/projectsSelector"
import { getStatisticsSelector, getstatisticsCountSelector } from "../../selectors/dashboardSelector"
import { getProjects } from "../../actions/projectsActions"
import { FormattedMessage, injectIntl } from 'react-intl';
import {Line} from 'react-chartjs-2';
import qs from "query-string"
import Select from "react-select"
import { DateRangeInput } from "@blueprintjs/datetime";

import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

const DashboardPage = ({location, intl, history}) => {
    const dispatch = useDispatch();

    const [scannerValue, setscannerValue] = useState([])
    const [projectValue, setprojectValue] = useState([])
    const [startDateFrom, setstartDateFrom] = useState(new Date(new Date().getTime() - 2592000000))
    const [startDateTo, setstartDateTo] = useState(new Date())

    const scanners = useSelector(getScannersSelector)
    const projects = useSelector(getProjectsSelector)

    const statistics = useSelector(getStatisticsSelector)
    const statisticsCount = useSelector(getstatisticsCountSelector)
    
    const data = {
        labels: statistics.labels,
        datasets: [
            {
                label: statistics.datasets[0] ? statistics.datasets[0].name : 'Scans',
                fill: false,
                lineTension: 0.1,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: 'rgba(75,192,192,1)',
                pointBackgroundColor: '#fff',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                pointHoverBorderColor: 'rgba(220,220,220,1)',
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: statistics.datasets[0] && statistics.datasets[0].data
            }
        ]
    };

    const mountFunction = () => {
        window.scrollTo(0,0)
        let l = location;
        let search = qs.parse(l.search);
        if (search.fromDate) {
            setstartDateFrom(new Date(parseInt(search.fromDate)))
        } else {
            search.fromDate = startDateFrom.getTime();
        }

        if (search.toDate) {
            setstartDateTo(new Date(parseInt(search.toDate)))
        } else {
            search.toDate = startDateTo.getTime();
        }
        search = qs.stringify(search);
        dispatch(push(`${location.pathname}?${search}`));

        dispatch(getScanners("", intl))
        dispatch(getProjects("", intl))
        dispatch(getStatistics(search, intl))
        dispatch(getStatisticsCount("", intl))
    }

    useEffect(mountFunction, [location.search])

    const changeScanner = (data) => {
        setscannerValue(data)
        
        let ids = []

        if (data) {
            ids = data.map(d => {
                return d.value
            })
        }
        let l = location
        let search = qs.parse(l.search)
        search.scannerId = ids
        l.search = qs.stringify(search)
        // dispatch(push(`${location.pathname}?${l.search}`))
        dispatch(getStatistics(l.search, intl))
    }

    const changeProject = (data) => {
        setprojectValue(data)
         let ids = []
        if (data) {
            ids = data.map(d => {
                return d.value
            })
        }
        let l = location
        let search = qs.parse(l.search)
        search.projectId = ids
        l.search = qs.stringify(search)
        // dispatch(push(`${location.pathname}?${l.search}`))
        dispatch(getStatistics(l.search, intl))
    }

    const handleChangeStart = (dates) => {
        var dateFrom = new Date(dates[0])
        var dateTo = new Date(dates[1])
        setstartDateFrom(dateFrom)
        setstartDateTo(dateTo)

        let l = location
        let search = qs.parse(l.search)
        search.fromDate = dateFrom.getTime()
        search.toDate = dateTo.getTime()
        l.search = qs.stringify(search)
        dispatch(push(`${location.pathname}?${l.search}`))
        dispatch(getStatistics(l.search, intl))
    }
    
    return (
        <div className="page-wrapper h-100">
            <div className="container-fluid">
                <div className="row page-titles">
                    <div className="col-md-2 align-self-center">
                        <h3 className="text-themecolor">
                            <FormattedMessage {...messages.title} />
                        </h3>
                    </div>
                    <div className="col-md-10 align-self-center d-none d-md-block">
                        <div className="row">
                            <div className="col-md-6"> 
                                <DateRangeInput
                                    formatDate={date => (date == null ? "" : date.toLocaleDateString())}
                                    // shortcuts={date_shortcuts}
                                    allowSingleDayRange={true}
                                    onChange={handleChangeStart}
                                    parseDate={str => new Date(Date.parse(str))}
                                    closeOnSelection={false}
                                    value={[startDateFrom, startDateTo]}
                                />
                            </div>
                            <div className="col-md-3"> 
                                <Select
                                    placeholder={intl.formatMessage(messages.scannersLabel)} 
                                    value={scannerValue}
                                    onChange={changeScanner}
                                    options={scanners.map(s => {
                                        return {label: s.name, value: s.id}
                                    })}
                                    isMulti={true}
                                />
                            </div>
                            <div className="col-md-3">
                                <Select
                                    placeholder={intl.formatMessage(messages.projectsLabel)} 
                                    value={projectValue}
                                    onChange={changeProject}
                                    options={projects.map(s => {
                                        return {label: s.name, value: s.id}
                                    })}
                                    isMulti={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex p-10 no-block">
                                    <div className="align-slef-center">
                                        <h2 className="m-b-0">{statisticsCount.userCount}</h2>
                                        <h6 className="text-muted m-b-0"><FormattedMessage {...messages.usersText} /></h6>
                                    </div>
                                    <div className="align-self-center display-6 ml-auto"><i className="text-success icon-Target-Market"></i></div>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width:"70%", height:"3px"}}> <span className="sr-only">50% Complete</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex p-10 no-block">
                                    <div className="align-slef-center">
                                        <h2 className="m-b-0">{statisticsCount.projectCount}</h2>
                                        <h6 className="text-muted m-b-0"><FormattedMessage {...messages.projectsText} /></h6>
                                    </div>
                                    <div className="align-self-center display-6 ml-auto"><i className="text-info icon-Dollar-Sign"></i></div>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar bg-info" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width:"70%", height:"3px"}}> <span className="sr-only">50% Complete</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex p-10 no-block">
                                    <div className="align-slef-center">
                                        <h2 className="m-b-0">{statisticsCount.scanCount}</h2>
                                        <h6 className="text-muted m-b-0"><FormattedMessage {...messages.scansText} /></h6>
                                    </div>
                                    <div className="align-self-center display-6 ml-auto"><i className="text-primary icon-Inbox-Forward"></i></div>
                                </div>
                            </div>
                            <div className="progress">
                                <div className="progress-bar bg-primary" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width:"70%", height:"3px"}}> <span className="sr-only">50% Complete</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card-body">
                        <div className="card col-md-12">
                            <Line height={70} data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(DashboardPage);