import Immutable from 'immutable';
import { LOGIN_USER, LOGOUT_USER, UPDATE_USER_IMAGE } from '../constants';

const INITIAL_STATE = Immutable.fromJS({user: localStorage.getItem("user") ? {...JSON.parse(localStorage.getItem("user")), isAuthenticated: true } : { isAuthenticated: false }});

export default function (state = INITIAL_STATE, action)
{
	let nextState = state.toJS();

	switch (action.type) {
		case LOGIN_USER:
            nextState.user = { ...nextState.user, ...action.payload }
            localStorage.setItem("user", JSON.stringify(nextState.user));
        break;
        case LOGOUT_USER:
            nextState.user = { isAuthenticated: false }
            localStorage.setItem("user", null);
        break;
        case UPDATE_USER_IMAGE:
            nextState.user = { ...nextState.user, ...{ profileImage: action.payload } }
            let lUser = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))

            if (lUser) {
                lUser.profileImage = action.payload;
                localStorage.setItem("user", JSON.stringify(lUser))
            }
        break;
        default:

        break;
	}

	return state.merge(nextState);
}
