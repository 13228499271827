import { defineMessages } from 'react-intl';

export const scope = 'app.components.Scanners';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Seznam skenerov'
  },
  searchString: {
    id: `${scope}.searchString`,
    defaultMessage: 'Vpišite iskalni niz'
  },
  searchButton: {
    id: `${scope}.searchButton`,
    defaultMessage: 'Išči'
  },
  projectsString: {
    id: `${scope}.projectsString`,
    defaultMessage: 'Skenere'
  },
  newProject: {
    id: `${scope}.newProject`,
    defaultMessage: 'Dodaj novi'
  },
  mtitle: {
    id: `${scope}.mtitle`,
    defaultMessage: 'New scanner'
  },
  errorText: {
    id: `${scope}.errorText`,
    defaultMessage: `Obvezno polje`,
  },
  postErrorText: {
    id: `${scope}.postErrorText`,
    defaultMessage: `Obvezno polje`,
  },
  Subtitle: {
    id: `${scope}.Subtitle`,
    defaultMessage: `Add data`,
  },
  namePlaceholder: {
    id: `${scope}.namePlaceholder`,
    defaultMessage: `Vpišite ime`
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: `Ime`
  },
  deleteString: {
    id: `${scope}.deleteString`,
    defaultMessage: `Ali ste sigurni da hočete zbrisat?`
  },
  companyLabel: {
    id: `${scope}.companyLabel`,
    defaultMessage: `Company`
  },
  companyPlaceholder: {
    id: `${scope}.companyPlaceholder`,
    defaultMessage: `Select company`
  }
});