import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Field, reduxForm } from 'redux-form/immutable';

import { FormattedMessage, injectIntl } from 'react-intl';

import { v4 as uuid } from 'uuid';
import { LOADING, otherModalStyles } from '../../../constants';
import AwsGetImageService from '../../../utils/awsImagesService';

const NewUserForm = ({
  userGroups,
  companies,
  insurances,
  openModal,
  closeModal,
  handleSubmit,
  messages,
  intl,
  addNew,
  loadXHR,
  user,
  originPage,
}) => {
  const dispatch = useDispatch();
  const [image, setimage] = useState(null);
  const [fullImage, setfullimage] = useState(null);
  const [groupSelected, setGroupSelected] = useState(null);
  const [groupSelectedId, setGroupSelectedId] = useState(null);

  var us = userGroups;

  if (user.userGroupName !== 'Super Admin') {
    us = userGroups.filter((u) => {
      return (
        u.name !== 'Super Admin' && u.name !== 'Insurance' && u.name !== 'Admin'
      );
    });
  }

  const submitF = (data) => {
    data = data.toJS();

    let fdata = {};
    if (data.companyId && data.companyId.value) {
      fdata.companyId = data.companyId.value;
    } else {
      fdata.companyId = null;
    }
    fdata.username = data.username;
    // if (data.userGroupId && data.userGroupId.value)
    //   fdata.userGroupId = data.userGroupId.value

    fdata.userGroupId = groupSelectedId;
    if (data.password) {
      fdata.password = data.password;
    }
    if (data.insuranceId && data.insuranceId.value) {
      fdata.insuranceId = data.insuranceId.value;
    } else {
      fdata.insuranceId = null;
    }

    //Set users companyID if nothing is submitted
    if (!fdata.companyId && !fdata.insuranceId) {
      fdata.companyId = user.companyId;
    }

    fdata.originPage = originPage;

    if (fullImage) {
      dispatch({ type: LOADING, payload: true });
      loadXHR(fullImage.preview).then(function (blob) {
        var name = uuid();
        var params = {
          Body: blob,
          Bucket: 'dcchailpublic',
          Key: `userimages/${name}.png`,
          ServerSideEncryption: 'AES256',
          StorageClass: 'STANDARD',
          ACL: 'public-read',
        };
        AwsGetImageService.putObject(params, (result) => {
          fdata.profileImage = result.Location;
          addNew(fdata);
        });
      });
    } else {
      dispatch({ type: LOADING, payload: true });
      addNew(fdata);
    }

    closeModal();
  };

  const onDrop = (file) => {
    if (file && file[0]) {
      setfullimage(file[0]);
      setimage(
        Object.assign(file[0], {
          preview: URL.createObjectURL(file[0]),
        })
      );
    }
  };

  const onGroupSelect = (group) => {
    setGroupSelected(group.label);
    setGroupSelectedId(group.value);
  };

  const dropzoneStyle = {
    width: '100px',
    height: '35px',
    cursor: 'pointer',
  };

  const img_style = {
    width: '200px',
    height: '160px',
  };

  return (
    <Modal
      isOpen={openModal}
      contentLabel="New project"
      onRequestClose={closeModal}
      ariaHideApp={false}
      style={otherModalStyles}
    >
      <div className="modal-wrapper">
        <div className="card-header bg-info">
          <h4 className="m-b-0 text-white">
            <FormattedMessage {...messages.new} />
          </h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(submitF)}>
            <div className="form-body">
              <h3 className="card-title">
                <FormattedMessage {...messages.newSubtitle} />
              </h3>
              <hr></hr>
              <div className="row p-t-20">
                <Field
                  name="username"
                  label={intl.formatMessage(messages.nameLabel)}
                  component={renderField}
                  errorText={intl.formatMessage(messages.errorText)}
                  palceholder={intl.formatMessage(messages.namePlaceholder)}
                  type="text"
                />

                <Field
                  name="password"
                  label={intl.formatMessage(messages.passwordLabel)}
                  component={renderField}
                  errorText={intl.formatMessage(messages.errorText)}
                  palceholder={intl.formatMessage(messages.passwordPlaceholder)}
                  type="password"
                />

                <Field
                  label={intl.formatMessage(messages.userGroupLabel)}
                  options={us.map((c) => {
                    return { label: c.name, value: c.id };
                  })}
                  name="userGroupId"
                  multi={false}
                  component={renderSelect}
                  errorText={intl.formatMessage(messages.errorText)}
                  placeholder={intl.formatMessage(
                    messages.userGroupPlaceholder
                  )}
                  onChange={onGroupSelect}
                />

                {user.userGroupName &&
                  user.userGroupName === 'Super Admin' &&
                  groupSelected &&
                  groupSelected !== 'Insurance' &&
                  groupSelected !== 'Super Admin' && (
                    <Field
                      label={intl.formatMessage(messages.companyLabel)}
                      options={companies.map((c) => {
                        return { label: c.name, value: c.id };
                      })}
                      name="companyId"
                      multi={false}
                      component={renderSelect}
                      errorText={intl.formatMessage(messages.errorText)}
                      placeholder={intl.formatMessage(
                        messages.companyPlaceholder
                      )}
                    />
                  )}

                {user.userGroupName &&
                  user.userGroupName === 'Super Admin' &&
                  groupSelected &&
                  groupSelected === 'Insurance' && (
                    <Field
                      label={intl.formatMessage(messages.insuranceLabel)}
                      options={insurances.map((c) => {
                        return { label: c.name, value: c.id };
                      })}
                      name="insuranceId"
                      multi={false}
                      component={renderSelect}
                      errorText={intl.formatMessage(messages.errorText)}
                      placeholder={intl.formatMessage(
                        messages.insurancesPlaceholder
                      )}
                    />
                  )}

                <div className="col-md-6">
                  <div className={`form-group`}>
                    <label className="control-label">
                      <FormattedMessage {...messages.imageString} />
                    </label>
                    <Dropzone
                      name="profile_image"
                      style={dropzoneStyle}
                      multiple={false}
                      onDrop={onDrop}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <FormattedMessage {...messages.addimageString} />
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {image && (
                      <img
                        alt="preview"
                        style={img_style}
                        src={image.preview}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right">
              <button type="submit" className="btn btn-success">
                <i className="fa fa-check"></i> Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
const renderField = ({
  input,
  label,
  palceholder,
  errorText,
  warningText,
  type,
  meta: { touched, error, warning, valid },
}) => (
  <div className="col-md-6">
    <div className={`form-group ${touched && error && 'has-danger'}`}>
      <label className="control-label">{label}</label>
      <input
        type={type}
        {...input}
        className="form-control"
        placeholder={palceholder}
      />
      {touched && error && (
        <span className="form-control-feedback">{errorText}</span>
      )}
    </div>
  </div>
);

const renderSelect = ({
  multi,
  input,
  placeholder,
  options,
  label,
  palceholder,
  errorText,
  warningText,
  type,
  meta: { touched, error, warning, valid },
}) => {
  return (
    <div className="col-md-6">
      <div className={`form-group ${touched && error && 'has-danger'}`}>
        <label className="control-label">{label}</label>
        <Select
          placeholder={placeholder}
          value={input.value}
          {...input}
          onBlur={() => {
            input.onBlur(input.value);
          }}
          options={options}
          isMulti={multi}
        />
        {touched && error && (
          <span className="form-control-feedback">{errorText}</span>
        )}
      </div>
    </div>
  );
};

const validate = (values) => {
  values = values.toJS();

  let errors = {};

  if (!values.username) {
    errors.username = true;
  }

  if (!values.password) {
    errors.password = true;
  }

  if (!values.userGroupId) {
    errors.userGroupId = true;
  }

  if (values.userGroupId && values.userGroupId.label === 'Insurance') {
    if (!values.insuranceId) {
      errors.insuranceId = true;
    }
  } else {
    if (!values.companyId) {
      errors.companyId = true;
    }
  }

  return errors;
};

export default reduxForm({
  validate,
  form: 'NewUserForm',
})(injectIntl(NewUserForm));
